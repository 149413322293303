import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Services
import { WINDOW_PROVIDERS } from "./services/windows.service";
// Pipes
// components
import { HeaderThreeComponent } from './header/header-three/header-three.component';
import { TopbarOneComponent } from './header/widgets/topbar/topbar-one/topbar-one.component';
import { NavbarComponent } from './header/widgets/navbar/navbar.component';
import { FooterOneComponent } from './footer/footer-one/footer-one.component';

@NgModule({
  exports: [
    CommonModule,
    TranslateModule,
    HeaderThreeComponent,
    FooterOneComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    HeaderThreeComponent,
    FooterOneComponent,
    NavbarComponent,
    TopbarOneComponent,
  ],
  providers: [
    WINDOW_PROVIDERS,
  ]
})
export class SharedModule { }
