import { Component, Inject, HostListener, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { WINDOW } from '../../services/windows.service';
import { Observable, of } from 'rxjs';
declare var $: any;

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-header-three',
  templateUrl: './header-three.component.html',
  styleUrls: ['./header-three.component.scss']
})
export class HeaderThreeComponent implements OnInit, OnDestroy {

  
  constructor(@Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window, private http: HttpClient, @Inject('API_ENDPOINT') private api_endpoint: string,  private ActivatedRoute: ActivatedRoute, private router: Router) { 
    
  }
  public show  :   boolean = false;
  // public route = null;
  public img = null;
  ngOnInit() {
    
    $.getScript('assets/js/menu.js');
    
      /* switch(this.router.url){
        case '/home/one': this.img = 'hkbkdegreelogo.png'; 
        break;
        case '/engineering': this.img = 'hkbkdegreelogo.png'; 
        break;
        case '/degree': this.img = 'hkbkdegreelogo.png'; 
        break;
        case '/evening-degree': this.img = 'hkbkdegreelogo.png'; 
        break;
        case '/physiotheraphy': this.img = 'hkbkdegreelogo.png'; 
        break;
        case '/puc': this.img = 'hkbkdegreelogo.png'; 
        break;
        default: this.img = 'hkbkdegreelogo.png'; 
      } 
      
      if(this.router.url == '/home/one' || this.router.url == '/engineering' || this.router.url == '/degree' || this.router.url == '/evening-degree' || this.router.url == '/physiotheraphy' || this.router.url == '/puc'){
        this.img = 'hkbkdegreelogo.png';
        $('#hkbkgrouplogo').css('display', 'block')
        $('#hkbkenglogo').css('display', 'none')
      }else{
        this.img = 'hkbkdegreelogo.png';
        $('#hkbkgrouplogo').css('display', 'none')
        $('#hkbkenglogo').css('display', 'block')
      }
      
      $(window).on('scroll', function() {
        
          if ($(this).scrollTop() > 100) {
            
            $('.sticky').find('.main-menu .pixelstrap a').css('color','#222 ');
            $('.sticky').find('.menu-content a').css('color','#222');
            $('#hkbkgrouplogo').attr('src','assets/images/uploads/hkbkdegreelogo.png');
            $('#hkbkenglogo').attr('src','assets/images/uploads/hkbkdegreelogo.png');
            $('.main-menu').css('border-bottom','1px solid #ccc');
            
          } else {
            $('.sticky').find('.main-menu .pixelstrap a').css('color','#fff ');
            $('.sticky').find('.menu-content a').css('color','#222');
            if($(window).width() < '1024'){
              $('.sticky').find('.main-menu .pixelstrap a').css('color','#222 ');
            }
            $('#hkbkgrouplogo').attr('src','assets/images/uploads/hkbkdegreelogo.png');
            $('#hkbkenglogo').attr('src','assets/images/uploads/hkbkdegreelogo.png');
            $('.main-menu').css('border-bottom','unset');
          } 
        
      });

      if($(window).width() < '480'){
        $(window).on('scroll', function() {
          if ($(this).scrollTop() > 50) {
            $('.brand-logo img').css('height', '70px');
            $('.brand-logo').css('left', '35%');
          } else {
            $('.brand-logo img').css('height', '60px');
            $('.brand-logo').css('left', '30%');
          }
        });
      } */
      
 

  }

  public openSearch() {
    this.show = true;
  }

  public closeSearch() {
    this.show = false;
  }

  ngOnDestroy() {
   
  }

  

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
      if (number >= 300) { 
        this.document.getElementById("sticky").classList.add('fixed');
      } else {
        this.document.getElementById("sticky").classList.remove('fixed');
      }
  }

}
