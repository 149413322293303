import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-single-portfolio',
  templateUrl: './single-portfolio.component.html',
  styleUrls: ['./single-portfolio.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SinglePortfolioComponent implements OnInit {

  constructor(private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  public person = null;

  ngOnInit() {
    this.ActivatedRoute.params.subscribe(params => {
      let designation = params['designation'];
      this.http.get<any>(this.api_endpoint+'/leadershipbydes/' + `${designation}`).subscribe(
        data => {
          if(data != null){
            this.person = data.data;
          }else{
            this.router.navigateByUrl('/home/one');
          }  
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        }); 
      });
  }

}
