import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Title, Meta, DomSanitizer } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PageComponent implements OnInit {
  page: any;
  sections = [];
  previousid: string;
  pagelink: string;

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    @Inject("API_ENDPOINT") private api_endpoint: string,
    private titleService: Title,
    private meta: Meta,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.ActivatedRoute.params.subscribe((params) => {
      this.pagelink = params["page-name"];

      this.http
        .get<any>(`${this.api_endpoint}/findByPageUrl/${this.pagelink}`)
        .subscribe(
          (data) => {
            if (data.data != null) {
              this.page = data.data;
              if (this.page.hasOwnProperty("description")) {
                this.page.description = this.sanitize(this.page.description);
              }
              if (this.page.metatitle) {
                this.titleService.setTitle(this.page.metatitle);
              }
              if (this.page.metadesc) {
                this.meta.updateTag({
                  name: "description",
                  content: this.page.metadesc,
                });
              }
              if (this.page.metakeyword) {
                this.meta.updateTag({
                  name: "keyword",
                  content: this.page.metakeyword,
                });
              }
              this.http
                .get<any>(
                  `${this.api_endpoint}/findsectionsbypageid/${this.page._id}`
                )
                .subscribe(
                  (data) => {
                    if (data.data.length) {
                      this.sections = data.data.map((row) => ({
                        ...row,
                        description: this.sanitize(row.description),
                      }));
                    } else {
                      this.sections = [];
                    }
                  },
                  (err: HttpErrorResponse) => {
                    if (err.error instanceof Error) {
                      console.log("Client-side error occured.");
                    } else {
                      console.log("Server-side error occured.");
                    }
                  }
                );
            } else {
              this.router.navigateByUrl("/");
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("Client-side error occured.");
            } else {
              console.log("Server-side error occured.");
            }
          }
        );
    });
  }

  sanitize(desc) {
    return this.sanitizer.bypassSecurityTrustHtml(desc);
  }

  public mobileSidebar() {
    $(".collection-filter").css("left", "-15px");
  }

  public mobileFilterBack() {
    $(".collection-filter").css("left", "-365px");
  }

  clickEvent(id, id1) {
    event.preventDefault();
    $(".page-content.default").css("display", "none");
    var currunt_href = id;
    $("#" + currunt_href).show();
    $("#" + this.previousid).hide();
    $(".href" + id)
      .parent()
      .parent()
      .find("li")
      .removeClass("active");
    $(".href" + id)
      .parent()
      .addClass("active");
    var elementPosition = $("#" + currunt_href)[0].getBoundingClientRect().top;
    var headerOffset = 160;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollBy(0, offsetPosition);
    this.previousid = id;
    // this.mobileSidebar = !this.mobileSidebar;
  }

  getSeperatedLinks(links) {
    return links.split(",") || [];
  }
}
