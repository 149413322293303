import { Component, OnInit, ViewEncapsulation, Inject, NgZone, ViewChild, ElementRef, EventEmitter, Output, AfterViewInit, Input   } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AchievementsComponent implements OnInit {

  constructor( private formBuilder: FormBuilder, private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  achievements = [];

  ngOnInit() {

    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });

    let url = this.router.url.replace(/\//g, '');
    var getData = '';
    switch(url) {
      case 'institution-achievements': getData = 'INSTITUTION ACHEIVEMENTS';
      break;
      case 'staff-achievements': getData = 'STAFF ACHEIVEMENTS';
      break;
      case 'student-achievements': getData = 'STUDENTS ACHEIVEMENTS';
      break;
      default: '';
    }
    this.http.get<any>(this.api_endpoint+'/achievementsbytype/' + getData).subscribe(
      data => {
        let data1 = removeDuplicates(data.data, "heading")
        data1.map(item => {
          return {
            heading: item.heading,
            subheading: data.data.filter(function(obj) {return obj.heading == item.heading}),
            _id: item._id
          }
        }).forEach(item => this.achievements.push(item));
        console.log(this.achievements)
        

        function removeDuplicates(originalArray, prop) {
          var newArray = [];
          var lookupObject  = {};
     
          for(var i in originalArray) {
             lookupObject[originalArray[i][prop]] = originalArray[i];
          }
     
          for(i in lookupObject) {
              newArray.push(lookupObject[i]);
          }
           return newArray;
      }
     
    
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });

  }

}
