import {
  Component,
  OnInit,
  ViewEncapsulation,
  Inject,
  NgZone,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  AfterViewInit,
  Input,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: "app-coursepage",
  templateUrl: "./coursepage.component.html",
  styleUrls: ["./coursepage.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CoursepageComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private ActivatedRoute: ActivatedRoute,
    private router: Router,
    @Inject("API_ENDPOINT") private api_endpoint: string,
    private sanitizer: DomSanitizer
  ) {}

  public course = null;
  public prevcode = null;
  public prevcountry = null;
  public courseid = null;
  public eligibilitys = [];
  public placements = [];
  public fee = null;
  public curriculum = [];
  public moredetails = [];
  public faculties = [];
  public countries = [];
  isShown: boolean = false;
  public justForm: FormGroup;
  checkvalue = "No";
  public Form: FormGroup;
  submitted = false;
  message = null;
  class = null;
  action = null;
  previousid: string;

  @Input() adressType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild("addresstext", { static: false }) addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        // componentRestrictions: { country: 'US' },
        types: ["(cities)"], // 'establishment' / 'address' / 'geocode'
      }
    );
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }

  ngOnInit() {
    this.Form = this.formBuilder.group({
      fullname: ["", Validators.required],
      mobile: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      current_city: ["", Validators.required],
      subcategory: ["", Validators.required],
      code: ["", Validators.required],
    });

    this.justForm = this.formBuilder.group({
      studenttype: [""],
    });
    /* 
    this.justForm.patchValue({
      studenttype: 'No',
    }); */
    this.http.get<any>(this.api_endpoint + "/country/").subscribe(
      (data) => {
        this.countries = data.data;
        // console.log(this.countries)
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      }
    );
    this.ActivatedRoute.params.subscribe((params) => {
      let pageName = params["courselink"];

      this.http
        .get<any>(this.api_endpoint + "/coursebycourselink/" + pageName)
        .subscribe(
          (data) => {
            if (data.data != null) {
              this.course = data.data;
              if (this.course.hasOwnProperty("industryInternship")) {
                this.course.industryInternship = this.sanitize(
                  this.course.industryInternship
                );
              }
              if (this.course.hasOwnProperty("jobOpportunities")) {
                this.course.jobOpportunities = this.sanitize(
                  this.course.jobOpportunities
                );
              }
              if (this.course.hasOwnProperty("eligibility")) {
                this.course.eligibility = this.sanitize(
                  this.course.eligibility
                );
              }
              if (this.course.hasOwnProperty("uniqueAdvantage")) {
                this.course.uniqueAdvantage = this.sanitize(
                  this.course.uniqueAdvantage
                );
              }
              if (this.course.hasOwnProperty("hodmessage")) {
                this.course.hodmessage = this.sanitize(
                  this.course.hodmessage
                );
              }
              if (this.course.hasOwnProperty("program_details")) {
                this.course.program_details = this.sanitize(
                  this.course.program_details
                );
              }
              if (this.course.hasOwnProperty("vision")) {
                this.course.vision = this.sanitize(
                  this.course.vision
                );
              }
              if (this.course.hasOwnProperty("mission")) {
                this.course.mission = this.sanitize(
                  this.course.mission
                );
              }
              this.courseid = data.data._id;

              //fees
              /* this.http.get<any>(this.api_endpoint+'/feecoursebyid/' + data.data._id).subscribe(
              data => {
                if(data.data != null){
                  this.fee = data.data;
                }
            },
            (err: HttpErrorResponse) => {
              if (err.error instanceof Error) {
                console.log("Client-side error occured.");
              } else {
                console.log("Server-side error occured.");
              }
            }); */
              //placements
              /*  this.http.get<any>(this.api_endpoint+'/placementscoursebyid/' + data.data._id).subscribe(
              data => {
                //console.log(data.message);
                //console.log(data.data);
                if(data.data != null){
                  this.placements = data.data;
                }
            },
            (err: HttpErrorResponse) => {
              if (err.error instanceof Error) {
                console.log("Client-side error occured.");
              } else {
                console.log("Server-side error occured.");
              }
            }); */
              //curriculum
              this.http
                .get<any>(
                  this.api_endpoint + "/curriculumcoursebyid/" + this.courseid
                )
                .subscribe(
                  (data) => {
                    console.log(
                      this.api_endpoint +
                        "/curriculumcoursebyid/" +
                        this.courseid
                    );
                    console.log(data.data);
                    if (data.data != null) {
                      this.curriculum = data.data;
                    }
                  },
                  (err: HttpErrorResponse) => {
                    if (err.error instanceof Error) {
                      console.log("Client-side error occured.");
                    } else {
                      console.log("Server-side error occured.");
                    }
                  }
                );
              //moredetails
              this.http
                .get<any>(
                  this.api_endpoint + "/moredetailscoursebyid/" + this.courseid
                )
                .subscribe(
                  (data) => {
                    // console.log(data.message);
                    //console.log(data.data);
                    if (data.data != null) {
                      this.moredetails = data.data.map((row) => ({
                        ...row,
                        description: this.sanitize(row.description),
                      }));
                      // console.log(this.moredetails)
                    }
                  },
                  (err: HttpErrorResponse) => {
                    if (err.error instanceof Error) {
                      console.log("Client-side error occured.");
                    } else {
                      console.log("Server-side error occured.");
                    }
                  }
                );
              //faculty
              /* this.http.get<any>(this.api_endpoint+'/findbydepartment/' + data.data.course).subscribe(
              data => {
                if(data.data != null){
                  this.faculties = data.data;
                }
            },
            (err: HttpErrorResponse) => {
              if (err.error instanceof Error) {
                console.log("Client-side error occured.");
              } else {
                console.log("Server-side error occured.");
              }
            }); */
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("Client-side error occured.");
            } else {
              console.log("Server-side error occured.");
            }
          }
        );
    });

    // $('#citytext').css('display', 'none');
    // $('#code').prepend('<option value="+91">India (+91)</option>')
    $("#code").on("change", function () {
      if (this.prevcode != null) {
        $("#code")
          .find("option[value='" + this.prevcode + "']")
          .text(this.prevcountry);
        // $('#code').find("option[value='+91']").remove()
        // $('#code').prepend('<option value="+91">India (+91)</option>')
        this.prevcode = $(this).val();
        this.prevcountry = $("#code")
          .find("option[value='" + this.prevcode + "']")
          .text();
      } else {
        this.prevcode = $(this).val();
        this.prevcountry = $("#code")
          .find("option[value='" + this.prevcode + "']")
          .text();
      }
      /* if($(this).val() != '+91'){
        $('#citytext').css('display', 'block');
        $('#cityselect, #state').css('display', 'none');
      }else{
        $('#cityselect, #state').css('display', 'block');
        $('#citytext').css('display', 'none');
      } */
      $("#code option:selected").text($(this).val());
    });

    $(".toggle-nav").on("click", function () {
      $(".sm-horizontal").css("right", "0px");
    });
    $(".mobile-back").on("click", function () {
      $(".sm-horizontal").css("right", "-410px");
    });

    $(".course-subnav a").on("click", function () {
      event.preventDefault();
      var currunt_href = $(this).attr("href");
      var elementPosition = $("#" + currunt_href)[0].getBoundingClientRect()
        .top;
      var headerOffset = 150;
      var offsetPosition = elementPosition - headerOffset;
      window.scrollBy(0, offsetPosition);
    });

    $(".see-more-list h5").click(function () {
      if ($(this).text() == "See More") {
        $(".see-more-list").css({ "max-height": "100%" });
      } else {
        $(".see-more-list").css({ "max-height": "320px" });
      }
      var moreAndLess = $(this).text() == "See More" ? "See Less" : "See More";
      $(this).text(moreAndLess);
    });
  }

  public mobileSidebar() {
    $(".collection-filter").css("left", "-15px");
  }

  public mobileFilterBack() {
    $(".collection-filter").css("left", "-365px");
  }

  clickEvent(id, id1) {
    event.preventDefault();
    $(".moredetails-content.default").css("display", "none");
    var currunt_href = id;
    $("#" + currunt_href).show();
    $("#" + this.previousid).hide();
    $(".href" + id)
      .parent()
      .parent()
      .find("li")
      .removeClass("active");
    $(".href" + id)
      .parent()
      .addClass("active");
    var elementPosition = $("#" + currunt_href)[0].getBoundingClientRect().top;
    var headerOffset = 160;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollBy(0, offsetPosition);
    this.previousid = id;
    // this.mobileSidebar = !this.mobileSidebar;
  }

  /* ngAfterViewInit() {
    var acc = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var currentAccordionindex = [i]
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = "100%";
        }
      });
    }
  } */

  public send(value) {
    if (value == "Yes") {
      this.checkvalue = "No";
    } else if (value == "No") {
      this.checkvalue = "Yes";
    }
  }

  get f() {
    return this.Form.controls;
  }

  onFormSubmit() {
    this.submitted = true;

    if (this.Form.invalid) {
      return;
    }
    var data = {
      fullname: this.Form.value.fullname,
      email: this.Form.value.email,
      mobile: this.Form.value.code + this.Form.value.mobile,
      current_city: this.Form.value.current_city,
      category: "Engineering",
      subcategory: this.Form.value.subcategory,
    };
    console.log(data);
    /* let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    this.http.post<any>('https://crm.hkbk.edu.in/pushlead/data/10021021/5b1e67aa3f06f63d16e9f900/5b20ba8a394f9f53cada733f', data.toString(), options).subscribe(
      res => {
        console.log(res);
        alert(res);
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log(err)
        console.log(err.error)
        console.log(Error)
        console.log("Server-side error occured.");
      }
    }); */

    this.http.post<any>(this.api_endpoint + "/courseemail", data).subscribe(
      (res) => {
        console.log(res);
        if (res.Error) {
          this.message = res.error;
          // this.class = 'btn btn-primary';
          return;
        } else {
          this.message = "Successfully Added";
          this.class = "btn btn-success";
        }
        // window.location.reload();
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log(Error);
          console.log(err.error);
          console.log(err);
          console.log("Server-side error occured.");
        }
      }
    );
  }

  sanitize(desc) {
    return this.sanitizer.bypassSecurityTrustHtml(desc);
  }
}
