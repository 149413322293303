import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-campus',
  templateUrl: './campus.component.html',
  styleUrls: ['./campus.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CampusComponent implements OnInit {

  constructor(private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  public facilities = [];
  public facility = null;
  public type = null;
  public campus = null;
  
  ngOnInit() {

    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });

    this.ActivatedRoute.params.subscribe(params => {
      let facility = params['facility'];

      switch(`${facility}`){
        case 'overview': this.type = 'overview'
        break;
        default: this.type = ''
      }

      this.http.get<any>(this.api_endpoint+'/campusoverviewfindone').subscribe(
        data => {
            this.campus = data.data;
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        }); 

      this.http.get<any>(this.api_endpoint+'/facilitiesoverview/').subscribe(
        data => {
            this.facilities = data.data;
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        }); 
      });
  }

}
