import { Component, OnInit, ViewEncapsulation, Inject, NgZone, ViewChild, ElementRef, EventEmitter, Output, AfterViewInit, Input   } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-faculty',
  templateUrl: './faculty.component.html',
  styleUrls: ['./faculty.component.scss']
})
export class FacultyComponent implements OnInit {
  page: any;

  constructor(private meta: Meta, private title: Title, private formBuilder: FormBuilder, private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  departments = [];
  faculties = [];

  ngOnInit() {

    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });

    this.http.get<any>(this.api_endpoint+'/pagesbyid/605ebe78f8b4884d0198bb2f').subscribe(
      data => {
        console.log(data)
        this.page = data.data;
  
        this.page.metadesc ? this.meta.updateTag({ name: 'description', content:  this.page.metadesc  }) : ''; 
        this.page.metakeyword ? this.meta.updateTag({ name: 'keyword', content: this.page.metakeyword }) : ''; 
        this.page.metatitle ? this.title.setTitle(this.page.metatitle) : '';
        // console.log(this.page)
      },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });

    this.http.get<any>(this.api_endpoint+'/facultyDepartment').subscribe(
      data => {
        this.departments = data.data;
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });

    this.getFaculty('5ee9cab889ef7a04eca6f844');

  }

  getFaculty(id) {
    this.http.get<any>(this.api_endpoint+'/findbydepartment/' + id).subscribe(
      data => {
        this.faculties = data.data;
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });
  }

}
