import { Routes } from '@angular/router';
import { HomeComponent } from '../app/shop/home/home.component';


export const rootRouterConfig: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  /* {
    path: '',
    children: [
      {
        path: 'home',
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
      },
    ]
  }, */
  {
    path: '**',
    redirectTo: '404'
  }
];

