import { Component, OnInit, Inject } from '@angular/core';
import { MENUITEMS, Menu } from './navbar-items';
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  
  public menuItems: Menu[];
  public courses = [];
  constructor(private http: HttpClient, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  ngOnInit() {
    this.menuItems = MENUITEMS.filter(menuItem => menuItem);
    
    this.http.get<any>(this.api_endpoint+'/courses').subscribe(
      data => {
        this.courses = data.data;
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
  });

  }

}
