import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Meta, Title, DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-news-detail-page",
  templateUrl: "./news-detail-page.component.html",
  styleUrls: ["./news-detail-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NewsDetailPageComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private ActivatedRoute: ActivatedRoute,
    @Inject("API_ENDPOINT") private api_endpoint: string,
    private meta: Meta,
    private title: Title,
    private sanitizer: DomSanitizer
    ) {}

  public data = null;
  page: any;

  ngOnInit() {
    this.http
      .get<any>(this.api_endpoint + "/pagesbyid/62b43b6df9b81820892d797d")
      .subscribe(
        (data) => {
          this.page = data.data;

          this.page.metadesc
            ? this.meta.updateTag({
                name: "description",
                content: this.page.metadesc,
              })
            : "";
          this.page.metakeyword
            ? this.meta.updateTag({
                name: "keyword",
                content: this.page.metakeyword,
              })
            : "";
          this.page.metatitle ? this.title.setTitle(this.page.metatitle) : "";
          // console.log(this.page)
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        }
      );

    this.ActivatedRoute.params.subscribe((params) => {
      let url = params["url"];

      this.http
        .get<any>(this.api_endpoint + "/campus-news-events-by-url/" + `${url}`)
        .subscribe(
          (data) => {
            if (data.data != null) {
              this.data = data.data;
              if(this.data.hasOwnProperty("description")){
                this.data.description = this.sanitize(this.data.description)
              }
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("Client-side error occured.");
            } else {
              console.log("Server-side error occured.");
            }
          }
        );
    });
  }

  sanitize(desc) {
    return this.sanitizer.bypassSecurityTrustHtml(desc);
  }
}
