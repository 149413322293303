import { Component, OnInit, Inject } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "../../../environments/environment";
declare var $: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public page = null;
  public courses = [];
  public news = [];
  public events = [];
  public campus_life = [];
  overview: any;
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  constructor(
    private http: HttpClient,
    @Inject("API_ENDPOINT") private api_endpoint: string
  ) {}

  ngOnInit() {
    $(".toggle-nav").on("click", function () {
      $(".sm-horizontal").css("right", "0px");
    });
    $(".mobile-back").on("click", function () {
      $(".sm-horizontal").css("right", "-410px");
    });
    this.http
      .get<any>(this.api_endpoint + "/pagesbyid/" + "5e2bf82f94fada2e3c25acd2")
      .subscribe(
        (data) => {
          this.page = data.data;
          // console.log(this.list_sections);
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        }
      );

    this.http.get<any>(this.api_endpoint + "/courses").subscribe(
      (data) => {
        this.courses = data.data.sort((a, b) => a.priority - b.priority);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      }
    );

    this.http
      .get<any>(this.api_endpoint + "/homepage-overview-findone")
      .subscribe(
        (data) => {
          this.overview = data.data;
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        }
      );

    this.http.get<any>(this.api_endpoint + "/campus-news-events").subscribe(
      (data) => {
        const news = data.data.filter((obj) => obj.type === "news");
        this.news = news.length > 4 ? news.splice(news.length - 4) : news;
        const events = data.data.filter((obj) => obj.type === "events");
        this.events =
          events.length > 4 ? events.splice(events.length - 4) : events;
        const campus_life = data.data.filter(
          (obj) => obj.type === "campus-life"
        );
        this.campus_life =
          campus_life.length > 3
            ? campus_life.splice(campus_life.length - 3)
            : campus_life;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      }
    );

    var a = 0;
    $(window).scroll(function () {
      var oTop = $(".home-about").offset().top - window.innerHeight;
      // alert(oTop);
      if (a == 0 && $(window).scrollTop() > oTop) {
        $(".counter-value").each(function () {
          var $this = $(this),
            countTo = $this.attr("data-count");
          $({
            countNum: $this.text(),
          }).animate(
            {
              countNum: countTo,
            },

            {
              duration: 2000,
              easing: "swing",
              step: function () {
                $this.text(Math.floor(this.countNum));
              },
              complete: function () {
                $this.text(this.countNum);
                //alert('finished');
              },
            }
          );
        });
        a = 1;
      }
    });
  }

  getDate(fulldate) {
    const date = fulldate.split("-");
    let daysufix = "";
    let month = "";
    
    const day = Number(date[2]) % 10
    switch (day) {
      case 1:
        daysufix = "st";
        break;
      case 2:
        daysufix = "nd";
        break;
      case 3:
        daysufix = "rd";
        break;
      default:
        daysufix = "th";
        break;
    }
    if (Number(date[2]) > 3 && Number(date[2]) < 21) {daysufix  ="th"};

    switch (date[1]) {
      case "01":
        month = "Jan";
        break;
      case "02":
        month = "Feb";
        break;
      case "03":
        month = "Mar";
        break;
      case "04":
        month = "Apr";
        break;
      case "05":
        month = "may";
        break;
      case "06":
        month = "Jun";
        break;
      case "07":
        month = "Jul";
        break;
      case "08":
        month = "Aug";
        break;
      case "09":
        month = "Sep";
        break;
      case "10":
        month = "Oct";
        break;
      case "11":
        month = "Nov";
        break;
      case "12":
        month = "Dec";
        break;
    }

    return date[2] + daysufix + " " + month + " " + date[0];
  }
  getYear(fulldate) {
    const date = fulldate.split("-");
    let daysufix = "";
    let month = "";
    
    const day = Number(date[2]) % 10
    switch (day) {
      case 1:
        daysufix = "st";
        break;
      case 2:
        daysufix = "nd";
        break;
      case 3:
        daysufix = "rd";
        break;
      default:
        daysufix = "th";
        break;
    }
    if (Number(date[2]) > 3 && Number(date[2]) < 21) {daysufix  ="th"};

    switch (date[1]) {
      case "01":
        month = "Jan";
        break;
      case "02":
        month = "Feb";
        break;
      case "03":
        month = "Mar";
        break;
      case "04":
        month = "Apr";
        break;
      case "05":
        month = "may";
        break;
      case "06":
        month = "Jun";
        break;
      case "07":
        month = "Jul";
        break;
      case "08":
        month = "Aug";
        break;
      case "09":
        month = "Sep";
        break;
      case "10":
        month = "Oct";
        break;
      case "11":
        month = "Nov";
        break;
      case "12":
        month = "Dec";
        break;
    }

    return  date[0];
  }
  getMonth(fulldate) {
    const date = fulldate.split("-");
    let daysufix = "";
    let month = "";
    
    const day = Number(date[2]) % 10
    switch (day) {
      case 1:
        daysufix = "st";
        break;
      case 2:
        daysufix = "nd";
        break;
      case 3:
        daysufix = "rd";
        break;
      default:
        daysufix = "th";
        break;
    }
    if (Number(date[2]) > 3 && Number(date[2]) < 21) {daysufix  ="th"};

    switch (date[1]) {
      case "01":
        month = "Jan";
        break;
      case "02":
        month = "Feb";
        break;
      case "03":
        month = "Mar";
        break;
      case "04":
        month = "Apr";
        break;
      case "05":
        month = "may";
        break;
      case "06":
        month = "Jun";
        break;
      case "07":
        month = "Jul";
        break;
      case "08":
        month = "Aug";
        break;
      case "09":
        month = "Sep";
        break;
      case "10":
        month = "Oct";
        break;
      case "11":
        month = "Nov";
        break;
      case "12":
        month = "Dec";
        break;
    }

    return month ;
  }

  getDat(fulldate) {
    const date = fulldate.split("-");
    let daysufix = "";
    let month = "";
    
    const day = Number(date[2]) % 10
    switch (day) {
      case 1:
        daysufix = "st";
        break;
      case 2:
        daysufix = "nd";
        break;
      case 3:
        daysufix = "rd";
        break;
      default:
        daysufix = "th";
        break;
    }
    if (Number(date[2]) > 3 && Number(date[2]) < 21) {daysufix  ="th"};

    switch (date[1]) {
      case "01":
        month = "Jan";
        break;
      case "02":
        month = "Feb";
        break;
      case "03":
        month = "Mar";
        break;
      case "04":
        month = "Apr";
        break;
      case "05":
        month = "may";
        break;
      case "06":
        month = "Jun";
        break;
      case "07":
        month = "Jul";
        break;
      case "08":
        month = "Aug";
        break;
      case "09":
        month = "Sep";
        break;
      case "10":
        month = "Oct";
        break;
      case "11":
        month = "Nov";
        break;
      case "12":
        month = "Dec";
        break;
    }

    return date[2] + daysufix ;
  }

  public catSlideConfig1 = {
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 586,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
}
