import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-academics',
  templateUrl: './academics.component.html',
  styleUrls: ['./academics.component.scss']
})
export class AcademicsComponent implements OnInit {

  constructor(private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  public academics = [];
  public type = null;

  ngOnInit() {
    // alert();
    this.ActivatedRoute.params.subscribe(params => {
      let academics = params['academics']; 

      switch(`${academics}`){
        case 'institute-industry-interaction': this.type = 'institute-industry-interaction'
        break;
        case 'curriculum-enhancement': this.type = 'curriculum-enhancement'
        break;
        case 'guest-lectures-workshops': this.type = 'guest-lectures-workshops'
        break;
        case 'workshops': this.type = 'workshops'
        break;
        case 'mentoring-and-advising': this.type = 'mentoring-and-advising'
        break;
        case 'innovations-of-students': this.type = 'innovations-of-students'
        break;
        case 'research-hkbk': this.type = 'research-hkbk'
        break;
        case 'conferences': this.type = 'conferences'
        break;
        case 'human-resource-development': this.type = 'human-resource-development'
        break;
        default: this.type = ''
      }

      this.http.get<any>(this.api_endpoint+'/findbyacademicstype/' + `${academics}`).subscribe(
        data => {
            this.academics = data.data;
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        }); 
      });
  }

}
