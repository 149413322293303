import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { BlogDetailsOneComponent } from "./home/blog-details-one/blog-details-one.component";
import { CoursepageComponent } from "./home/coursepage/coursepage.component";
import { ContactUsComponent } from "./home/contact-us/contact-us.component";
import { AboutUsComponent } from "./home/about-us/about-us.component";
import { VisionMissionComponent } from "./home/vision-mission/vision-mission.component";
import { SinglePortfolioComponent } from "./home/single-portfolio/single-portfolio.component";
import { CampusComponent } from "./home/campus/campus.component";
import { PlacementsComponent } from "./home/placements/placements.component";
import { AcademicsComponent } from "./home/academics/academics.component";
import { LandingpageComponent } from "./home/landingpage/landingpage.component";
import { AdmissionsComponent } from "./home/admissions/admissions.component";
import { LifehkbkComponent } from "./home/lifehkbk/lifehkbk.component";
import { SlidermenuComponent } from "./home/slidermenu/slidermenu.component";
import { IqacComponent } from "./home/iqac/iqac.component";
import { FacultyComponent } from "./home/faculty/faculty.component";
import { IndustryConnectComponent } from "./home/industry-connect/industry-connect.component";
import { RankingsRecognitionComponent } from "./home/rankings-recognition/rankings-recognition.component";
import { AllNewsComponent } from "./home/all-news/all-news.component";
import { NewsDetailPageComponent } from "./home/news-detail-page/news-detail-page.component";
import { LibrariesLabsComponent } from "./home/libraries-labs/libraries-labs.component";
import { LearningResourcesComponent } from "./home/learning-resources/learning-resources.component";
import { LearningResourcesDetailComponent } from "./home/learning-resources-detail/learning-resources-detail.component";
import { TrainingDevelopmentComponent } from "./home/training-development/training-development.component";
import { MembersComponent } from "./home/members/members.component";
import { FacilitiesComponent } from "./home/facilities/facilities.component";
import { FacilitiesoverviewComponent } from "./home/facilitiesoverview/facilitiesoverview.component";
import { GalleryComponent } from "./home/gallery/gallery.component";
import { ErrorPageComponent } from "./home/error-page/error-page.component";
import { InsuranceComponent } from "./home/insurance/insurance.component";
import { OrgStructureComponent } from "./home/org-structure/org-structure.component";
import { ObjectiveComponent } from "./home/objective/objective.component";
import { MessagesComponent } from "./home/messages/messages.component";
import { AchievementsComponent } from "./home/achievements/achievements.component";
import { UpcomingeventsComponent } from "./home/upcomingevents/upcomingevents.component";
import { InternationalStudentsComponent } from "./home/international-students/international-students.component";
import { StudentInfrastructureFeedbackComponent } from "./home/student-infrastructure-feedback/student-infrastructure-feedback.component";
import { FeedbackComponent } from "./home/feedback/feedback.component";
import { StudentCourseFeedbackComponent } from "./home/student-course-feedback/student-course-feedback.component";
import { AlumniFeedbackComponent } from "./home/alumni-feedback/alumni-feedback.component";
import { ParentsFeedbackComponent } from "./home/parents-feedback/parents-feedback.component";
import { AdmissionProcessComponent } from "./home/admission-process/admission-process.component";
import { CanteenComponent } from "./home/canteen/canteen.component";
import { HostelComponent } from "./home/hostel/hostel.component";
import { TransportationFacilityComponent } from "./home/transportation-facility/transportation-facility.component";
import { OurRecruitersComponent } from "./home/our-recruiters/our-recruiters.component";
import { PageComponent } from "./home/page/page.component";
import { NewsComponent } from "./home/news/news.component";
import { EventsComponent } from "./home/events/events.component";
import { EventsDetailComponent } from "./home/events-detail/events-detail.component";
import { CampusLifeDetailComponent } from "./home/campus-life-detail/campus-life-detail.component";
import { CampusLifeComponent } from "./home/campus-life/campus-life.component";
import { NaacComponent } from "./home/naac/naac.component";

// Routes
const routes: Routes = [
  {
    path: "degree",
    component: HomeComponent,
  },
  {
    path: "404",
    component: ErrorPageComponent,
  },
  {
    path: "course/:courselink",
    component: CoursepageComponent,
  },
  /* {
    path: 'BBA',
    component: CoursepageComponent
  },
  {
    path: 'BBA-Aviation',
    component: CoursepageComponent
  },
  {
    path: 'BCA',
    component: CoursepageComponent
  },
  {
    path: 'BSC',
    component: CoursepageComponent
  }, */
  /* {
    path: 'about',
    component: AboutUsComponent
  },
  {
    path: 'vision-mission',
    component: VisionMissionComponent
  }, */
  /* {
    path: 'admissions',
    component: AdmissionsComponent
  }, */
  {
    path: "contact",
    component: ContactUsComponent,
  },
  {
    path: "members",
    component: MembersComponent,
  },
  /* {
    path: 'sports',
    component: FacilitiesComponent
  }, */
  /* {
    path: 'nss',
    component: FacilitiesComponent
  }, */
  /* {
    path: "news",
    component: AllNewsComponent,
  }, */
  /* {
    path: 'facilities',
    component: FacilitiesoverviewComponent
  }, */
  {
    path: "gallery",
    component: GalleryComponent,
  },
  {
    path: "faculty",
    component: FacultyComponent,
  },
  /* {
    path: 'library',
    component: LibrariesLabsComponent
  }, */
  /* {
    path: 'insurance',
    component: InsuranceComponent
  }, */
  {
    path: "internal-org-strcture",
    component: OrgStructureComponent,
  },
  /* {
    path: 'objective',
    component: ObjectiveComponent
  }, */
  {
    path: "messages/chairman",
    component: MessagesComponent,
  },
  {
    path: "messages/director",
    component: MessagesComponent,
  },
  {
    path: "messages/secretary",
    component: MessagesComponent,
  },
  {
    path: "messages/principal",
    component: MessagesComponent,
  },
  /* {
    path: 'institution-achievements',
    component: AchievementsComponent
  }, */
  /* {
    path: 'staff-achievements',
    component: AchievementsComponent
  }, */
  /* {
    path: 'student-achievements',
    component: AchievementsComponent
  }, */
  /* {
    path: 'naac',
    component: IqacComponent
  }, */
  /* {
    path: 'upcomingevents',
    component: UpcomingeventsComponent
  }, */
  /* {
    path: 'international-students',
    component: InternationalStudentsComponent
  }, */
  /* {
    path: 'placements',
    component: PlacementsComponent
  }, */
  {
    path: 'naac',
    component: NaacComponent
  },
  {
    path: "feedback",
    component: FeedbackComponent,
  },
  {
    path: "student-course-feedback",
    component: StudentCourseFeedbackComponent,
  },
  {
    path: "student-infrastructure-feedback",
    component: StudentInfrastructureFeedbackComponent,
  },
  {
    path: "alumni-feedback",
    component: AlumniFeedbackComponent,
  },
  {
    path: "parents-feedback",
    component: ParentsFeedbackComponent,
  },
  {
    path: "news",
    component: NewsComponent,
  },
  {
    path: "news-detail/:url",
    component: NewsDetailPageComponent,
  },
  {
    path: "events",
    component: EventsComponent,
  },
  {
    path: "event-detail/:url",
    component: EventsDetailComponent,
  },
  {
    path: "campus-life",
    component: CampusLifeComponent,
  },
  {
    path: "campus-life-detail/:url",
    component: CampusLifeDetailComponent,
  },
  /* {
    path: 'admission-process',
    component: AdmissionProcessComponent
  }, */
  /* {
    path: 'canteen',
    component: CanteenComponent
  }, */
  /* {
    path: 'hostel',
    component: HostelComponent
  }, */
  /* {
    path: 'transportation-facility',
    component: TransportationFacilityComponent
  }, */
  /* {
    path: 'our-recruiters',
    component: OurRecruitersComponent
  }, */
  {
    path: ":page-name",
    component: PageComponent,
  },
  /* 
  
  
  {
    path: 'training-development',
    component: TrainingDevelopmentComponent
  },
  {
    path: 'leadership/:designation',
    component: SinglePortfolioComponent
  },
  {
    path: 'facility/:facility',
    component: CampusComponent
  },
  {
    path: 'learning-resources',
    component: LearningResourcesComponent
  },
  {
    path: 'learning-resources-detail/:resources',
    component: LearningResourcesDetailComponent
  },
  {
    path: 'our-institutions/:institution',
    component: LandingpageComponent
  },
  
  {
    path: 'life@hkbk/:lifehkbk',
    component: LifehkbkComponent
  },
  {
    path: 'iqac',
    component: IqacComponent
  },
  {
    path: 'faculty/:faculty',
    component: FacultyComponent
  },
  {
    path: 'institute-industry-connect',
    component: IndustryConnectComponent
  },
  {
    path: 'rankings-recognition',
    component: RankingsRecognitionComponent
  },
  
  

  
  {
    path: 'academics/:academics',
    component: AcademicsComponent
  },
  
  
  {
    path: 'blogs/:id',
    component: BlogDetailsOneComponent
  }, */
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShopRoutingModule {}
