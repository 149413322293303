import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Inject, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
declare var $: any;


@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {

  constructor(private http: HttpClient, @Inject('API_ENDPOINT') private api_endpoint: string) { 
  	$.getScript('assets/js/portfolio.js');
  }

  members = []

  ngOnInit() {
    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });
    
    this.http.get<any>(this.api_endpoint+'/leadership/').subscribe(
      data => {
        this.members = data.data;
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });
  }
  public mobileFilter() {
    $('.collection-filter').css("left", "-15px");
  }

}
