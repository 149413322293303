import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-rankings-recognition',
  templateUrl: './rankings-recognition.component.html',
  styleUrls: ['./rankings-recognition.component.scss']
})
export class RankingsRecognitionComponent implements OnInit {

  constructor(private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  public rankings1 = [];
  public rankings2 = [];
  public rankings3 = [];
  public rankings4 = [];
  public rankings5 = [];
  ngOnInit() {
    
    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });

    this.http.get<any>(this.api_endpoint+'/rankingsbyheading/GOVERNMENT AFFILIATIONS').subscribe(
    data => {
        this.rankings1 = data.data;
        console.log(this.rankings1)
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });

    this.http.get<any>(this.api_endpoint+'/rankingsbyheading/UNIVERSITY AFFILIATIONS').subscribe(
    data => {
        this.rankings2 = data.data;
        console.log(this.rankings2)
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });
    this.http.get<any>(this.api_endpoint+'/rankingsbyheading/INTERNATIONAL TIEUPS').subscribe(
    data => {
        this.rankings3 = data.data;
        console.log(this.rankings3)
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });
    this.http.get<any>(this.api_endpoint+'/rankingsbyheading/ACCREDITATION').subscribe(
    data => {
        this.rankings4 = data.data;
        console.log(this.rankings4)
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });
    this.http.get<any>(this.api_endpoint+'/rankingsbyheading/RANKING').subscribe(
    data => {
        this.rankings5 = data.data;
        console.log(this.rankings5)
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });
  }

}
