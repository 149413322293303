import { Component, OnInit, ViewEncapsulation, Inject  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingpageComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  public courses = [];
  public advancedengineering = [];
  public institutionpage = null;
  public institution = null;
  public institutioncourse = null;

 

  ngOnInit() {

    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });
    
    this.ActivatedRoute.params.subscribe(params => {
      let institution = params['institution'];

      switch(`${institution}`){
        case 'engineering': this.institution = 'College of Engineering', this.institutioncourse = 'Engineering'
        break;
        case 'degree-college': this.institution = 'Degree College', this.institutioncourse = 'Degree'
        break;
        case 'evening-degree-college': this.institution = 'Evening Degree College', this.institutioncourse = 'Degree'
        break;
        case 'physiotherapy': this.institution = 'Physiotheraphy', this.institutioncourse = 'Physiotheraphy'
        break;
        case 'puc': this.institution = 'PUC', this.institutioncourse = 'PUC'
        break;
        default: this.institution = ''
      }
      this.http.get<any>(this.api_endpoint+'/ourinstitutionsbyinstitution/' + this.institution).subscribe(
        data => {
          if(data.data != null){
            this.institutionpage = data.data;
          }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });

      this.http.get<any>(this.api_endpoint+'/coursess/' + this.institutioncourse).subscribe(
        data => {
          if(data.data != null){
            this.courses = data.data;
          }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });

    });
    
    
    /* this.http.get<any>(this.api_endpoint+'/advancedengineering/').subscribe(
      data => {
        if(data.data != null){
          this.advancedengineering = data.data;
        }
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    }); */
  }

  public productSlideConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
};

}
