import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-learning-resources-detail',
  templateUrl: './learning-resources-detail.component.html',
  styleUrls: ['./learning-resources-detail.component.scss']
})
export class LearningResourcesDetailComponent implements OnInit {

  public learningresource = null;
  public learningresourceuploads = [];
  public learningresourceurl : SafeResourceUrl;
  public elementSrc = [];

  constructor(public sanitizer:DomSanitizer, private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  
  ngOnInit() {
    this.ActivatedRoute.params.subscribe(params => {
      let resources = params['resources'];
      this.http.get<any>(this.api_endpoint+'/findbylearningresourcesheading/' + `${resources}`).subscribe(
        data => {
            
            this.learningresource = data.data;
            this.learningresourceurl = this.sanitizer.bypassSecurityTrustResourceUrl(data.data.image);
            // console.log(this.learningresource)
            this.http.get<any>(this.api_endpoint+'/findallbylearningresourcesuid/' + data.data._id).subscribe(
              data => {
                  
                  // this.learningresourceuploads = data.data;
                  // console.log(this.learningresourceuploads)
                  /* for(let data1 of data.data){
                    this.learningresourceuploads = [
                      {
                        format: data.data[i].format,
                        contentlink: data.data[i].contentlink,
                        heading: data.data[i].heading,
                        iframelink: this.sanitizer.bypassSecurityTrustResourceUrl(data.data[i].contentlink),
                      },
                    ]
                  } */
                  data.data.map(item => {
                    return {
                      format: item.format,
                      contentlink: item.contentlink,
                      heading: item.heading,
                      iframelink: this.sanitizer.bypassSecurityTrustResourceUrl(item.contentlink),
                    }
                }).forEach(item => this.learningresourceuploads.push(item));
                  console.log(this.learningresourceuploads)
              },
              (err: HttpErrorResponse) => {
                if (err.error instanceof Error) {
                  console.log("Client-side error occured.");
                } else {
                  console.log("Server-side error occured.");
                }
              });
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        }); 
    });
  }

}
