import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-industry-connect',
  templateUrl: './industry-connect.component.html',
  styleUrls: ['./industry-connect.component.scss']
})
export class IndustryConnectComponent implements OnInit {

  constructor(private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  public industryconnects = [];

  ngOnInit() {
    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });
    
    this.http.get<any>(this.api_endpoint+'/industryconnect/').subscribe(
      data => {
          
          this.industryconnects = data.data;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
  }

}
