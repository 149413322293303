import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-slidermenu',
  templateUrl: './slidermenu.component.html',
  styleUrls: ['./slidermenu.component.scss']
})
export class SlidermenuComponent implements OnInit {

  constructor(private http: HttpClient, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  public homebannermenu = [];
  homebannerSection: boolean = false;
  
  ngOnInit() {
    this.http.get<any>(this.api_endpoint+'/homebannermenufindone/').subscribe(
      data => {
        this.homebannermenu = data.data;
        
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
  });
  this.http.get<any>(this.api_endpoint+'/sectionsbypageid/'+'5e3bc737e0f3ab2fd0da653d'+'/5e357a212e66f8213c278fc4').subscribe(
    data => {
        if(data.data != null){
          this.homebannerSection = true;
        }else{
          this.homebannerSection = false;
        }
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });
  }

}
