import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-collection-banner',
  templateUrl: './collection-banner.component.html',
  styleUrls: ['./collection-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CollectionBannerComponent implements OnInit {

  constructor(private http: HttpClient, @Inject('API_ENDPOINT') private api_endpoint: string) { }


  public homebannermenu = [];
  public homerank = [];
  homebannerSection: boolean = false;
  homerankSection: boolean = false;

  ngOnInit() { 
    
      this.http.get<any>(this.api_endpoint+'/homebannermenufindone/').subscribe(
          data => {
            this.homebannermenu = data.data;
            
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
      });
      this.http.get<any>(this.api_endpoint+'/homeranksection').subscribe(
          data => {
            this.homerank = data.data;
            
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
      });

      this.http.get<any>(this.api_endpoint+'/sectionsbypageid/'+'5e3bc737e0f3ab2fd0da653d'+'/5e357a212e66f8213c278fc4').subscribe(
      data => {
          if(data.data != null){
            this.homebannerSection = true;
          }else{
            this.homebannerSection = false;
          }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });

      this.http.get<any>(this.api_endpoint+'/sectionsbypageid/'+'5e4a61fbc0ab322698f26b06'+'/5e357a212e66f8213c278fc4').subscribe(
      data => {
          if(data.data != null){
            this.homerankSection = true;
          }else{
            this.homerankSection = false;
          }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
      
    
  }

  onNavigate(url1){
    const url = 'https://www.google.com';
    window.open(url1, '_blank');
  }

  // Collection banner
  public category = [{
    image: 'assets/images/sub-banner1.jpg',
    save: 'save 50%',
    title: 'men',
    link: '/home/left-sidebar/collection/men'
  }, {
    image: 'assets/images/sub-banner2.jpg',
    save: 'save 50%',
    title: 'women',
    link: '/home/left-sidebar/collection/women'
  }]

  public catSlideConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 586,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

}
