import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var $:any

@Component({
  selector: 'app-students-life-sidebar',
  templateUrl: './students-life-sidebar.component.html',
  styleUrls: ['./students-life-sidebar.component.scss']
})
export class StudentsLifeSidebarComponent implements OnInit {

  page = null

  constructor(private router: Router) { }

  ngOnInit() {
    const url = this.router.url.replace(/\//g, '');
    switch(url) {
      case 'facilities': this.page = 'facilities';
      break;
      case 'library': this.page = 'library';
      break;
      case 'canteen': this.page = 'canteen';
      break;
      case 'sports': this.page = 'sports';
      break;
      case 'hostel': this.page = 'hostel';
      break;
      case 'transportation-facility': this.page = 'transportation-facility';
      break;
      case 'upcomingevents': this.page = 'upcomingevents';
      break;
      default: this.page = '';
    }
  }

  

  // For mobile view
  public mobileFilterBack() {
    $('.collection-filter').css("left", "-365px");
  }

  

}
