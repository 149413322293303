import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-admissions',
  templateUrl: './admissions.component.html',
  styleUrls: ['./admissions.component.scss']
})
export class AdmissionsComponent implements OnInit {

  page: any;
  constructor(private meta: Meta, private title: Title, private http: HttpClient, private ActivatedRoute: ActivatedRoute,
    private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  public type = null;
  public engineeringcourses = [];
  public degreecourses = [];
  public managementcourses = [];
  public puccourses = [];
  public physioteraphycourses = [];

  public mobileFilter() {
    $('.collection-filter').css("left", "-15px");
  }
  
  ngOnInit() {
    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });

    this.http.get<any>(this.api_endpoint+'/pagesbyid/605ec0b4f8b4884d0198bb37').subscribe(
      data => {
        console.log(data)
        this.page = data.data;
  
        this.page.metadesc ? this.meta.updateTag({ name: 'description', content:  this.page.metadesc  }) : ''; 
        this.page.metakeyword ? this.meta.updateTag({ name: 'keyword', content: this.page.metakeyword }) : ''; 
        this.page.metatitle ? this.title.setTitle(this.page.metatitle) : '';
        // console.log(this.page)
      },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });

    this.ActivatedRoute.params.subscribe(params => {
      let admission = params['admission']; 

      switch(`${admission}`){
        case 'admission': this.type = 'admission'
        break;
        default: this.type = ''
      }

      });
      this.http.get<any>(this.api_endpoint+'/coursess/Engineering').subscribe(
        data => {
            this.engineeringcourses = data.data;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
      this.http.get<any>(this.api_endpoint+'/coursess/Degree').subscribe(
        data => {
            this.degreecourses = data.data;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
      this.http.get<any>(this.api_endpoint+'/coursess/Management').subscribe(
        data => {
            this.managementcourses = data.data;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
      this.http.get<any>(this.api_endpoint+'/coursess/PUC').subscribe(
        data => {
            this.puccourses = data.data;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
      this.http.get<any>(this.api_endpoint+'/coursess/Physioteraphy').subscribe(
        data => {
            this.physioteraphycourses = data.data;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
  }

}
