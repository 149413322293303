import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, Inject, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-learning-resources',
  templateUrl: './learning-resources.component.html',
  styleUrls: ['./learning-resources.component.scss']
})

export class LearningResourcesComponent implements OnInit {

  constructor(private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  public checkedTagsArray: any[] = [];

  public learningResources = [];
  searchText: string;
  

  ngOnInit() {
    this.http.get<any>(this.api_endpoint+'/learningresources').subscribe(
      data => {
        if(data.data != null){
          this.learningResources = data.data;
        }
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });

      $('.collapse-block-title').on('click', function(e) {
        e.preventDefault;
        var speed = 300;
        var thisItem = $(this).parent(),
          nextLevel = $(this).next('.collection-collapse-block-content');
        if (thisItem.hasClass('open')) {
          thisItem.removeClass('open');
          nextLevel.slideUp(speed);
        } else {
          thisItem.addClass('open');
          nextLevel.slideDown(speed);
        }
    });
  }

  public clearChecked() {
    this.checkedTagsArray.splice(0, this.checkedTagsArray.length);
  }
  public mobileFilter() {
    $('.collection-filter').css("left", "-15px");
  }
  public mobileFilterBack() {
    $('.collection-filter').css("left", "-365px");
  }

  checkedFilter(event){
    let index = this.checkedTagsArray.indexOf(event.target.value);  // checked and unchecked value
     if (event.target.checked)   
         this.checkedTagsArray.push({checked: event.target.value}); // push in array cheked value
      else 
         this.checkedTagsArray.splice(index,1);  // removed in array unchecked value 
         this.searchText = event.target.value
        //  alert(this.checkedTagsArray)          
}


}
