import { Component, OnInit, ViewEncapsulation, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Meta, Title, DomSanitizer } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MessagesComponent implements OnInit {
  page: any;
  constructor(
    private meta: Meta,
    private title: Title,
    private http: HttpClient,
    private ActivatedRoute: ActivatedRoute,
    private router: Router,
    @Inject("API_ENDPOINT") private api_endpoint: string,
    private sanitizer: DomSanitizer
  ) {}

  public messages = null;
  public message_by = null;

  ngOnInit() {
    let urlsplit = this.router.url.split("/");
    console.log(urlsplit);
    this.message_by = urlsplit[2].toUpperCase();
    $(".toggle-nav").on("click", function () {
      $(".sm-horizontal").css("right", "0px");
    });
    $(".mobile-back").on("click", function () {
      $(".sm-horizontal").css("right", "-410px");
    });

    this.http
      .get<any>(this.api_endpoint + "/pagesbyid/605ebf80f8b4884d0198bb33")
      .subscribe(
        (data) => {
          console.log(data);
          this.page = data.data;

          this.page.metadesc
            ? this.meta.updateTag({
                name: "description",
                content: this.page.metadesc,
              })
            : "";
          this.page.metakeyword
            ? this.meta.updateTag({
                name: "keyword",
                content: this.page.metakeyword,
              })
            : "";
          this.page.metatitle ? this.title.setTitle(this.page.metatitle) : "";
          // console.log(this.page)
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        }
      );

    this.http.get<any>(this.api_endpoint + "/messages/").subscribe(
      (data) => {
        this.messages = data.data.map(row => ({
          ...row,
          description: this.sanitize(row.description),
        }));
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      }
    );
  }

  sanitize(desc) {
    return this.sanitizer.bypassSecurityTrustHtml(desc);
  }
}
