import { Component, OnInit, Inject } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.scss"],
})
export class NewsComponent implements OnInit {
  constructor(
    private http: HttpClient,
    @Inject("API_ENDPOINT") private api_endpoint: string,
    private meta: Meta,
    private title: Title
  ) {}

  list: [];
  page: any;

  ngOnInit() {
    this.http
      .get<any>(this.api_endpoint + "/pagesbyid/62b43445f9b81820892d7976")
      .subscribe(
        (data) => {
          this.page = data.data;

          this.page.metadesc
            ? this.meta.updateTag({
                name: "description",
                content: this.page.metadesc,
              })
            : "";
          this.page.metakeyword
            ? this.meta.updateTag({
                name: "keyword",
                content: this.page.metakeyword,
              })
            : "";
          this.page.metatitle ? this.title.setTitle(this.page.metatitle) : "";
          // console.log(this.page)
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        }
      );

    this.http
      .get<any>(this.api_endpoint + "/campus-news-events-by-type/news")
      .subscribe(
        (data) => {
          this.list = data.data;
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        }
      );
  }
}
