import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var $:any

@Component({
  selector: 'app-about-us-sidebar',
  templateUrl: './about-us-sidebar.component.html',
  styleUrls: ['./about-us-sidebar.component.scss']
})
export class AboutUsSidebarComponent implements OnInit {

  page = null

  constructor(private router: Router) { }

  ngOnInit() {
    const url = this.router.url.replace(/\//g, '');
    switch(url) {
      case 'about': this.page = 'about';
      break;
      case 'vision-mission': this.page = 'vision-mission';
      break;
      case 'faculty': this.page = 'faculty';
      break;
      case 'messageschairman': this.page = 'messageschairman';
      break;
      case 'messagesdirector': this.page = 'messagesdirector';
      break;
      case 'messagessecretory': this.page = 'messagessecretory';
      break;
      case 'messagesprincipal': this.page = 'messagesprincipal';
      break;
      default: this.page = '';
    }
  }

  

  // For mobile view
  public mobileFilterBack() {
    $('.collection-filter').css("left", "-365px");
  }

  

}
