import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-student-course-feedback',
  templateUrl: './student-course-feedback.component.html',
  styleUrls: ['./student-course-feedback.component.scss']
})
export class StudentCourseFeedbackComponent implements OnInit {

  page: any;

  constructor(private meta: Meta, private title: Title, private router: Router, private formBuilder: FormBuilder,
     private http: HttpClient, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  public Form: FormGroup;
  message = null;
  class = null;
  submitted = null;

  ngOnInit() {

    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });

    this.http.get<any>(this.api_endpoint+'/pagesbyid/605ecce1f8b4884d0198bb53').subscribe(
      data => {
        this.page = data.data;
  
        this.page.metadesc ? this.meta.updateTag({ name: 'description', content:  this.page.metadesc  }) : ''; 
        this.page.metakeyword ? this.meta.updateTag({ name: 'keyword', content: this.page.metakeyword }) : ''; 
        this.page.metatitle ? this.title.setTitle(this.page.metatitle) : '';
        // console.log(this.page)
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
    });

    this.Form = this.formBuilder.group({
      course: ['', Validators.required],
      semister: ['', Validators.required],
      teacher: ['', Validators.required],
      roll: ['', Validators.required],
      attendence: ['', Validators.required],
      paper: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      gender: ['', Validators.required],
      category: ['', Validators.required],
      state: ['', Validators.required],
      nationality: [''],
      mobile: ['', Validators.required],
      join: ['', Validators.required],
      paperDifficulty: [''],
      workload: [''],
      compare: [''],
      contribution: [''],
      q1: [''],
      q2: [''],
      q3: [''],
      q4: [''],
      q5: [''],
      q6: [''],
      q7: [''],
      q8: [''],
      q9: [''],
      q10: [''],
      q11: [''],
      q12: [''],
      q13: [''],
      q14: [''],
      q15: [''],
      q16: [''],
      q17: [''],
      q18: [''],
    })
  }

  get f() { return this.Form.controls; }

  onFormSubmit() {
    
    this.submitted = true;

    if (this.Form.invalid) {
      return;
    }

    this.http.post<any>(this.api_endpoint+'/studentcoursefeedback', this.Form.value).subscribe(
      res => {
        if(res.Error){
          this.message = 'Something Went Wrong. Please Try Again Later.';
          this.class = 'btn btn-primary';
          window.scrollTo(document.documentElement.clientWidth, 0);
        }else{
          // this.message = 'Successfully Submitted';
          // this.class = 'btn btn-success';
          alert('Successfully Submitted');
          let navigate = this.router.navigateByUrl('/feedback');
        } 
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured."+err);
      }
    });

  }

}
