import { Component, OnInit, Input, Inject, ViewEncapsulation } from '@angular/core';
declare var $: any;
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-product-tab',
  templateUrl: './product-tab.component.html',
  styleUrls: ['./product-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductTabComponent implements OnInit {


  constructor(private http: HttpClient, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  aluminiplacementsSection: boolean = false;
  public aluminiplacement = null;
  public homecontents = null;
  public companies = [];

  ngOnInit() {
    // tab js
  	  $("#tab-1").css("display", "Block");
	  $(".default").css("display", "Block");
	  $(".tabs li a").on('click', function() {
	    event.preventDefault();
	    $(this).parent().parent().find("li").removeClass("current");
	    $(this).parent().addClass("current");
	    var currunt_href = $(this).attr("href");
	    $('#' + currunt_href).show();
	    $(this).parent().parent().parent().find(".tab-content").not('#' + currunt_href).css("display", "none");
	  });

	this.http.get<any>(this.api_endpoint+'/sectionsbypageid/'+'5e4cfa2f9ecc2e185083c6aa'+'/5e357a212e66f8213c278fc4').subscribe(
        data => {
          if(data.data != null){
            this.aluminiplacement = data.data;
            this.aluminiplacementsSection = true;
          }else{
            this.aluminiplacementsSection = false;
          }
      	},
      	(err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          	console.log("Client-side error occured.");
        } else {
          	console.log("Server-side error occured.");
        }
	});
	  
	this.http.get<any>(this.api_endpoint+'/placementslimit12').subscribe(
		data => {
		  	this.companies = data.data;
	  	},
	  	(err: HttpErrorResponse) => {
		if (err.error instanceof Error) {
		  	console.log("Client-side error occured.");
		} else {
		  	console.log("Server-side error occured.");
		}
	});
	this.http.get<any>(this.api_endpoint+'/homebannermenufindone').subscribe(
			data => {
			this.homecontents = data.data;
			// alert(this.homecontents.url6)
		},
		(err: HttpErrorResponse) => {
			if (err.error instanceof Error) {
			console.log("Client-side error occured.");
			} else {
			console.log("Server-side error occured.");
			}
		});
	}



}
