import { Component, OnInit, ViewEncapsulation, Inject, NgZone, ViewChild, ElementRef, EventEmitter, Output, AfterViewInit, Input   } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  page: any;

  constructor(private meta: Meta, private title: Title, private formBuilder: FormBuilder,
    private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router,
     @Inject('API_ENDPOINT') private api_endpoint: string, private ngZone: NgZone) { }

  public Form: FormGroup;
  submitted = false;
  message = null;
  class = null;
  action = null;

  ngOnInit() {
    this.Form = this.formBuilder.group({
      fullname: ['', Validators.required],
      mobile: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
    });

    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });

    this.http.get<any>(this.api_endpoint+'/pagesbyid/605eca2ef8b4884d0198bb4f').subscribe(
      data => {
        this.page = data.data;
  
        this.page.metadesc ? this.meta.updateTag({ name: 'description', content:  this.page.metadesc  }) : ''; 
        this.page.metakeyword ? this.meta.updateTag({ name: 'keyword', content: this.page.metakeyword }) : ''; 
        this.page.metatitle ? this.title.setTitle(this.page.metatitle) : '';
        // console.log(this.page)
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
    });
  }

  get f() { return this.Form.controls; }

  onFormSubmit() {
    this.submitted = true;

    if (this.Form.invalid) {
      return;
    }
    var data = {
      "fullname"      : this.Form.value.fullname,
      "email"      : this.Form.value.email,
      "mobile"      : this.Form.value.mobile,
      "message"      : this.Form.value.message,
    }
    console.log(data)
    /* let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    this.http.post<any>('https://crm.hkbk.edu.in/pushlead/data/10021021/5b1e67aa3f06f63d16e9f900/5b20ba8a394f9f53cada733f', data.toString(), options).subscribe(
      res => {
        console.log(res);
        alert(res);
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log(err)
        console.log(err.error)
        console.log(Error)
        console.log("Server-side error occured.");
      }
    }); */

    this.http.post<any>(this.api_endpoint+'/generalemail', data).subscribe(
      res => {
        console.log(res);
        if(res.Error){
          this.message = res.error;
          // this.class = 'btn btn-primary';
          alert('Please Try Again Later')
          return
        }else{
          this.message = 'Successfully Added';
          this.class = 'btn btn-success';
        }
        // window.location.reload();
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });
  }

}
