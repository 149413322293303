import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Inject, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-facilitiesoverview',
  templateUrl: './facilitiesoverview.component.html',
  styleUrls: ['./facilitiesoverview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FacilitiesoverviewComponent implements OnInit, AfterViewInit  {

  @ViewChild('gallery', { static: true }) galleryElement: ElementRef;	

  page: any;
  constructor(private meta: Meta, private title: Title, private http: HttpClient, @Inject('API_ENDPOINT') private api_endpoint: string) { 
  	$.getScript('assets/js/portfolio.js');
  }

  facilitiesoverview = null;
  facilities = [];

  ngAfterViewInit() {
  	$(this.galleryElement.nativeElement).magnificPopup({
        delegate: 'a',
        type: 'image',
        closeOnContentClick: false,
        closeBtnInside: false,
        mainClass: 'mfp-with-zoom mfp-img-mobile',
        image: {
            verticalFit: true,
            titleSrc: function(item) {
                return item.el.attr('title') + ' &middot;';
            }
        },
        gallery: {
            enabled: true
        },
        zoom: {
            enabled: true,
            duration: 300, // don't foget to change the duration also in CSS
            opener: function(element) {
                return element.find('img');
            }
        }
    });   
  }
  
  ngOnInit() {

    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });

    this.http.get<any>(this.api_endpoint+'/pagesbyid/605ec256f8b4884d0198bb3b').subscribe(
      data => {
        console.log(data)
        this.page = data.data;
  
        this.page.metadesc ? this.meta.updateTag({ name: 'description', content:  this.page.metadesc  }) : ''; 
        this.page.metakeyword ? this.meta.updateTag({ name: 'keyword', content: this.page.metakeyword }) : ''; 
        this.page.metatitle ? this.title.setTitle(this.page.metatitle) : '';
        // console.log(this.page)
      },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });

    this.http.get<any>(this.api_endpoint+'/campusoverviewfindone/').subscribe(
      data => {
        this.facilitiesoverview = data.data;
        console.log(this.facilitiesoverview)
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });
    this.http.get<any>(this.api_endpoint+'/hkbkFacilities/').subscribe(
      data => {
        let facilities = data.data
        this.http.get<any>(this.api_endpoint+'/hkbkFacilitiesHeading/').subscribe(
          data => {
            data.data.map(item => {
              return{
                heading: item.heading,
                content: facilities.filter( function ( obj ) { return obj.heading  == item._id; })
              }
            }).forEach(element => this.facilities.push(element));
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        });
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });
  }

  

}
