import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Inject, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.scss']
})
export class FacilitiesComponent implements OnInit, AfterViewInit {

  @ViewChild('gallery', { static: true }) galleryElement: ElementRef;	

  page: any;
  constructor(private meta: Meta, private title: Title, private http: HttpClient, @Inject('API_ENDPOINT') private api_endpoint: string) { 
  	$.getScript('assets/js/portfolio.js');
  }

  clubs = []

  ngOnInit() {

    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });

    $("#tab-1").css("display", "Block");
	  $(".default").css("display", "Block");
	  $(".tabs li a").on('click', function() {
	    event.preventDefault();
	    $(this).parent().parent().find("li").removeClass("current");
	    $(this).parent().addClass("current");
	   /*  var currunt_href = $(this).attr("href");
	    $('#' + currunt_href).show();
	    $(this).parent().parent().parent().find(".tab-content").not('#' + currunt_href).css("display", "none"); */
	  });

    this.http.get<any>(this.api_endpoint+'/pagesbyid/605ec67ef8b4884d0198bb41').subscribe(
      data => {
        console.log(data)
        this.page = data.data;
  
        this.page.metadesc ? this.meta.updateTag({ name: 'description', content:  this.page.metadesc  }) : ''; 
        this.page.metakeyword ? this.meta.updateTag({ name: 'keyword', content: this.page.metakeyword }) : ''; 
        this.page.metatitle ? this.title.setTitle(this.page.metatitle) : '';
        // console.log(this.page)
      },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });

    this.http.get<any>(this.api_endpoint+'/clubtypejoin/').subscribe(
      data => {
        this.clubs = data.data;
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });
  }

  ngAfterViewInit() {
  	$(this.galleryElement.nativeElement).magnificPopup({
        delegate: 'a',
        type: 'image',
        closeOnContentClick: false,
        closeBtnInside: false,
        mainClass: 'mfp-with-zoom mfp-img-mobile',
        image: {
            verticalFit: true,
            titleSrc: function(item) {
                return item.el.attr('title') + ' &middot;';
            }
        },
        gallery: {
            enabled: true
        },
        zoom: {
            enabled: true,
            duration: 300, // don't foget to change the duration also in CSS
            opener: function(element) {
                return element.find('img');
            }
        }
    });   
  }

}
