import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Title, Meta, DomSanitizer } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: "app-naac",
  templateUrl: "./naac.component.html",
  styleUrls: ["./naac.component.scss"]
})
export class NaacComponent implements OnInit {
  constructor(
    private ActivatedRoute: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    @Inject("API_ENDPOINT") private api_endpoint: string,
    private title: Title,
    private meta: Meta,
    private sanitizer: DomSanitizer
  ) {}

  naac_list = [];
  page: any;
  previousid: string = null;

  ngOnInit() {
    this.http
      .get<any>(this.api_endpoint + "/pagesbyid/605ec8c8f8b4884d0198bb49")
      .subscribe(
        (data) => {
          this.page = data.data;

          this.page.metadesc
            ? this.meta.updateTag({
                name: "description",
                content: this.page.metadesc
              })
            : "";
          this.page.metakeyword
            ? this.meta.updateTag({
                name: "keyword",
                content: this.page.metakeyword
              })
            : "";
          this.page.metatitle ? this.title.setTitle(this.page.metatitle) : "";
          // console.log(this.page)
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        }
      );

    this.http
      .get<any>(this.api_endpoint + "/naac-heading-and-sub-heading/")
      .subscribe(
        (data) => {
          if (data.data.length) {
            this.naac_list = data.data;
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
        }
      );
  }

  public mobileSidebar() {
    $(".collection-filter").css("left", "-15px");
  }

  public mobileFilterBack() {
    $(".collection-filter").css("left", "-365px");
  }

  clickEvent(id, id1) {
    event.preventDefault();
    $(".naac-content.default").css("display", "none");
    var currunt_href = id;
    $("#" + currunt_href).show();
    $("#" + this.previousid ? this.previousid : this.naac_list[0]._id).hide();
    $(".href" + id)
      .parent()
      .parent()
      .find("li")
      .removeClass("active");
    $(".href" + id)
      .parent()
      .addClass("active");
    var elementPosition = $("#" + currunt_href)[0].getBoundingClientRect().top;
    var headerOffset = 160;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollBy(0, offsetPosition);
    this.previousid = id;
    // this.mobileSidebar = !this.mobileSidebar;
  }

  sanitize(desc) {
    return this.sanitizer.bypassSecurityTrustHtml(desc);
  }

  activateTab(id) {
    event.preventDefault();
    $(".nav-material .tab-pane").removeClass("show active");
    $("#" + id).addClass("show active");
  }
}
