import { Component, OnInit, ViewEncapsulation, Inject } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-collection-slider-one",
  templateUrl: "./collection-slider-one.component.html",
  styleUrls: ["./collection-slider-one.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CollectionSliderOneComponent implements OnInit {
  constructor(
    private http: HttpClient,
    @Inject("API_ENDPOINT") private api_endpoint: string
  ) {}

  public recruiters = [];

  ngOnInit() {
    this.http.get<any>(this.api_endpoint + "/recruiter/").subscribe(
      (data) => {
        this.recruiters = data.data;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      }
    );
  }

  // Slick slider config
  public teamSliderConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 586,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
}
