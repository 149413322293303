import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-libraries-labs',
  templateUrl: './libraries-labs.component.html',
  styleUrls: ['./libraries-labs.component.scss']
})
export class LibrariesLabsComponent implements OnInit {

  page: any;
  constructor(private meta: Meta, private title: Title, private http: HttpClient, private ActivatedRoute: ActivatedRoute,
    private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  public library = null;

  ngOnInit() {

    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });

    this.http.get<any>(this.api_endpoint+'/pagesbyid/605ec458f8b4884d0198bb3d').subscribe(
      data => {
        console.log(data)
        this.page = data.data;
  
        this.page.metadesc ? this.meta.updateTag({ name: 'description', content:  this.page.metadesc  }) : ''; 
        this.page.metakeyword ? this.meta.updateTag({ name: 'keyword', content: this.page.metakeyword }) : ''; 
        this.page.metatitle ? this.title.setTitle(this.page.metatitle) : '';
        // console.log(this.page)
      },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });

    this.http.get<any>(this.api_endpoint+'/libraryfindone/').subscribe(
      data => {
        
          this.library = data.data;
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });
  }

}
