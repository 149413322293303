import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar-one.component.html',
  styleUrls: ['./topbar-one.component.scss']
})
export class TopbarOneComponent implements OnInit {
  
  public show  :   boolean = false;
  
  constructor() { }

  ngOnInit() { }

  public openSearch() {
    this.show = true;
  }

  public closeSearch() {
    this.show = false;
  }

}
