import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShopRoutingModule } from './shop-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BarRatingModule } from "ngx-bar-rating";
import { RangeSliderModule  } from 'ngx-rangeslider-component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxImgZoomModule } from 'ngx-img-zoom';
// Home-one components
import { HomeComponent } from './home/home.component';
import { SliderComponent } from './home/slider/slider.component';
import { AboutUsComponent } from './home/about-us/about-us.component';
import { AboutCoursesComponent } from './home/about-courses/about-courses.component';
import { CollectionBannerComponent } from './home/collection-banner/collection-banner.component';
import { ProductSliderComponent } from './home/product-slider/product-slider.component';
import { ParallaxBannerComponent } from './home/parallax-banner/parallax-banner.component';
import { ProductTabComponent } from './home/product-tab/product-tab.component';
import { ServicesComponent } from './home/services/services.component';
import { BlogComponent } from './home/blog/blog.component';
import { BlogDetailsOneComponent } from './home/blog-details-one/blog-details-one.component';
import { InstagramComponent } from './home/instagram/instagram.component';
import { LogoComponent } from './home/logo/logo.component';
import { CollectionSliderOneComponent } from './home/collection-slider-one/collection-slider-one.component';
import { CoursepageComponent } from './home/coursepage/coursepage.component';
import { ContactUsComponent } from './home/contact-us/contact-us.component';
import { VisionMissionComponent } from './home/vision-mission/vision-mission.component';
import { SinglePortfolioComponent } from './home/single-portfolio/single-portfolio.component';
import { CampusComponent } from './home/campus/campus.component';
import { PlacementsComponent } from './home/placements/placements.component';
import { AcademicsComponent } from './home/academics/academics.component';
import { LandingpageComponent } from './home/landingpage/landingpage.component';
import { AdmissionsComponent } from './home/admissions/admissions.component';
import { LifehkbkComponent } from './home/lifehkbk/lifehkbk.component';
import { SlidermenuComponent } from './home/slidermenu/slidermenu.component';
import { PlacementStatisticsComponent } from './home/placement-statistics/placement-statistics.component';
import { CountToModule } from 'angular-count-to';
import { IqacComponent } from './home/iqac/iqac.component';
import { FacultyComponent } from './home/faculty/faculty.component';
import { IndustryConnectComponent } from './home/industry-connect/industry-connect.component';
import { RankingsRecognitionComponent } from './home/rankings-recognition/rankings-recognition.component';
import { AllNewsComponent } from './home/all-news/all-news.component';
import { NewsDetailPageComponent } from './home/news-detail-page/news-detail-page.component';
import { LibrariesLabsComponent } from './home/libraries-labs/libraries-labs.component';
import { LearningResourcesComponent } from './home/learning-resources/learning-resources.component';
import { NgxGalleryModule } from 'ngx-gallery';
import { LearningResourcesDetailComponent } from './home/learning-resources-detail/learning-resources-detail.component';
import { TrainingDevelopmentComponent } from './home/training-development/training-development.component';
import { FilterPipe} from './home/learning-resources/filter.pipe';
import { AboutUsSidebarComponent } from './home/about-us-sidebar/about-us-sidebar.component';
import { AdmissionsSidebarComponent } from './home/admissions-sidebar/admissions-sidebar.component';
import { MembersComponent } from './home/members/members.component';
import { FacilitiesComponent } from './home/facilities/facilities.component';
import { FacilitiesoverviewComponent } from './home/facilitiesoverview/facilitiesoverview.component';
import { GalleryComponent } from './home/gallery/gallery.component';
import { ErrorPageComponent } from './home/error-page/error-page.component';
import { InsuranceComponent } from './home/insurance/insurance.component';
import { OrgStructureComponent } from './home/org-structure/org-structure.component';
import { ObjectiveComponent } from './home/objective/objective.component';
import { MessagesComponent } from './home/messages/messages.component';
import { AchievementsComponent } from './home/achievements/achievements.component';
import { UpcomingeventsComponent } from './home/upcomingevents/upcomingevents.component';
import { InternationalStudentsComponent } from './home/international-students/international-students.component';
import { FeedbackComponent } from './home/feedback/feedback.component';
import { StudentCourseFeedbackComponent } from './home/student-course-feedback/student-course-feedback.component';
import { StudentInfrastructureFeedbackComponent } from './home/student-infrastructure-feedback/student-infrastructure-feedback.component';
import { AlumniFeedbackComponent } from './home/alumni-feedback/alumni-feedback.component';
import { ParentsFeedbackComponent } from './home/parents-feedback/parents-feedback.component';
import { AdmissionProcessComponent } from './home/admission-process/admission-process.component';
import { CanteenComponent } from './home/canteen/canteen.component';
import { HostelComponent } from './home/hostel/hostel.component';
import { TransportationFacilityComponent } from './home/transportation-facility/transportation-facility.component';
import { StudentsLifeSidebarComponent } from './home/students-life-sidebar/students-life-sidebar.component';
import { PlacementsSidebarComponent } from './home/placements-sidebar/placements-sidebar.component';
import { OurRecruitersComponent } from './home/our-recruiters/our-recruiters.component';
import { PageComponent } from './home/page/page.component';
import { NewsComponent } from './home/news/news.component';
import { EventsComponent } from './home/events/events.component';
import { EventsDetailComponent } from './home/events-detail/events-detail.component';
import { CampusLifeComponent } from './home/campus-life/campus-life.component';
import { CampusLifeDetailComponent } from './home/campus-life-detail/campus-life-detail.component';
import { NaacComponent } from './home/naac/naac.component';

@NgModule({
  exports: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShopRoutingModule,
    SharedModule,
    SlickCarouselModule,
    BarRatingModule,
    RangeSliderModule,
    InfiniteScrollModule,
    NgxPayPalModule,
    NgxImgZoomModule,
    CountToModule,
    NgxGalleryModule
  ],
  declarations: [
    // Home one
    HomeComponent,
    SliderComponent,
	AboutUsComponent,
    AboutCoursesComponent,
    CollectionBannerComponent,
    ProductSliderComponent,
    ParallaxBannerComponent,
    ProductTabComponent,
    ServicesComponent,
    BlogComponent,
    InstagramComponent,
    LogoComponent,
    CollectionSliderOneComponent,
    BlogDetailsOneComponent,
    CoursepageComponent,
    ContactUsComponent,
    VisionMissionComponent,
    SinglePortfolioComponent,
    CampusComponent,
    PlacementsComponent,
    AcademicsComponent,
    LandingpageComponent,
    AdmissionsComponent,
    LifehkbkComponent,
    SlidermenuComponent,
    PlacementStatisticsComponent,
    IqacComponent,
    FacultyComponent,
    IndustryConnectComponent,
    RankingsRecognitionComponent,
    AllNewsComponent,
    NewsDetailPageComponent,
    LibrariesLabsComponent,
    LearningResourcesComponent,
    LearningResourcesDetailComponent,
    TrainingDevelopmentComponent,
    FilterPipe,
    AboutUsSidebarComponent,
    AdmissionsSidebarComponent,
    MembersComponent,
    FacilitiesComponent,
    FacilitiesoverviewComponent,
    GalleryComponent,
    ErrorPageComponent,
    InsuranceComponent,
    OrgStructureComponent,
    ObjectiveComponent,
    MessagesComponent,
    AchievementsComponent,
    UpcomingeventsComponent,
    InternationalStudentsComponent,
    FeedbackComponent,
    StudentCourseFeedbackComponent,
    StudentInfrastructureFeedbackComponent,
    AlumniFeedbackComponent,
    ParentsFeedbackComponent,
    AdmissionProcessComponent,
    CanteenComponent,
    HostelComponent,
    TransportationFacilityComponent,
    StudentsLifeSidebarComponent,
    PlacementsSidebarComponent,
    OurRecruitersComponent,
    PageComponent,
    NewsComponent,
    EventsComponent,
    EventsDetailComponent,
    CampusLifeComponent,
    CampusLifeDetailComponent,
    NaacComponent
    
  ]
})
export class ShopModule { }
