import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
declare var PureCounter: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	
   constructor(translate: TranslateService) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
   }
   ngOnInit() {
      new PureCounter(); // Initialize PureCounter.js
    }
	
}
