import { Component, OnInit, ViewEncapsulation, Inject, NgZone, ViewChild, ElementRef, EventEmitter, Output, AfterViewInit, Input   } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  constructor( private formBuilder: FormBuilder, private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string, private ngZone: NgZone) { }

  public Form: FormGroup;
  submitted = false;
  message = null;
  class = null;
  action = null;
  public countries = [];
  public prevcode = null;
  public prevcountry = null;

    @Input() adressType: string;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @ViewChild('addresstext', {static: false}) addresstext: any;

    autocompleteInput: string;
    queryWait: boolean;

  ngOnInit() {

    this.Form = this.formBuilder.group({
      fullname: ['', Validators.required],
      mobile: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      current_city: ['', Validators.required],
      subcategory: ['', Validators.required],
      code: ['', Validators.required],
    });

    var contentwidth = $(window).width();
  if ((contentwidth) < '750') {
    $('.footer-title h4').append('<span class="according-menu"></span>');
    $('.footer-title').on('click', function() {
      $('.footer-title').removeClass('active');
      $('.footer-contant').slideUp('normal');
      if ($(this).next().is(':hidden') == true) {
        $(this).addClass('active');
        $(this).next().slideDown('normal');
      }
    });
    $('.footer-contant').hide();
  } else {
    $('.footer-contant').show();
  }
  
    this.http.get<any>(this.api_endpoint+'/country/').subscribe(
      data => {
          this.countries = data.data;
          // console.log(this.countries)
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });

    // $('#state, #cityselect').css('display', 'none');
    // $('#code').prepend('<option value="+91">India (+91)</option>')
    $("#code").on('change', function () {
      if(this.prevcode != null){
        $('#code').find("option[value='"+this.prevcode+"']").text(this.prevcountry)
        // $('#code').find("option[value='+91']").remove()
        // $('#code').prepend('<option value="+91">India (+91)</option>')
        this.prevcode = $(this).val();
        this.prevcountry = $('#code').find("option[value='"+this.prevcode+"']").text();
      }else{
        this.prevcode = $(this).val();
        this.prevcountry = $('#code').find("option[value='"+this.prevcode+"']").text();
      }
      /* if($(this).val() != '+91'){
        $('#citytext').css('display', 'block');
        $('#cityselect, #state').css('display', 'none');
      }else{
        $('#cityselect, #state').css('display', 'block');
        $('#citytext').css('display', 'none');
      } */
      $('#code option:selected').text($(this).val());
    });

    /* this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
 
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
 
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
 
          
        });
      });
    }); */

  }
  /* ngAfterViewInit() {
    this.getPlaceAutocomplete();
  } */

  /* private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
        {
            // componentRestrictions: { country: 'US' },
            types: ["(cities)"]  // 'establishment' / 'address' / 'geocode'
        });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        this.invokeEvent(place);
    });
  } */

  invokeEvent(place: Object) {
      this.setAddress.emit(place);
  }

  get f() { return this.Form.controls; }

  onFormSubmit() {
    this.submitted = true;

    if (this.Form.invalid) {
      return;
    }
    var data = {
      "fullname"      : this.Form.value.fullname,
      "email"      : this.Form.value.email,
      "mobile"      : this.Form.value.code+this.Form.value.mobile,
      "current_city"      : this.Form.value.current_city,
      "category"      : 'Engineering',
      "subcategory"      : this.Form.value.subcategory,
    }
    console.log(data)
    /* let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    this.http.post<any>('https://crm.hkbk.edu.in/pushlead/data/10021021/5b1e67aa3f06f63d16e9f900/5b20ba8a394f9f53cada733f', data.toString(), options).subscribe(
      res => {
        console.log(res);
        alert(res);
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log(err)
        console.log(err.error)
        console.log(Error)
        console.log("Server-side error occured.");
      }
    }); */

    this.http.post<any>(this.api_endpoint+'/courseemail', data).subscribe(
      res => {
        console.log(res);
        if(res.Error){
          this.message = res.error;
          // this.class = 'btn btn-primary';
          return
        }else{
          this.message = 'Successfully Added';
          this.class = 'btn btn-success';
        }
        // window.location.reload();
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });
  }

}

