import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-placement-statistics',
  templateUrl: './placement-statistics.component.html',
  styleUrls: ['./placement-statistics.component.scss']
})
export class PlacementStatisticsComponent implements OnInit {

  public placementsoverviews = null;

  constructor(private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  ngOnInit() {
    this.http.get<any>(this.api_endpoint+'/placementsoverviewfindone').subscribe(
      data => {
          this.placementsoverviews = data.data;
          // console.log(this.placementsoverviews);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
    }); 
  }

}
