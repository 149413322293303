import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-about',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AboutUsComponent implements OnInit {

  constructor(private meta: Meta, private title: Title, private http: HttpClient, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  public about = null;
  public chairman = null;
  public bog = [];
  public leadershipt = [];
  public members = [];
  public governors = [];
  public page = null;

  ngOnInit() {
    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });

    
    this.http
    .get<any>(`${this.api_endpoint}/findByPageUrl/about`)
    .subscribe(
      (data) => {
        if (data.data != null) {
          this.page = data.data;
          if (this.page.metatitle) {
            this.title.setTitle(this.page.metatitle);
          }
          if (this.page.metadesc) {
            this.meta.updateTag({
              name: "description",
              content: this.page.metadesc,
            });
          }
          if (this.page.metakeyword) {
            this.meta.updateTag({
              name: "keyword",
              content: this.page.metakeyword,
            });
          }
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      }
    );
  }

   // For mobile filter view
   public mobileFilter() {
    $('.collection-filter').css("left", "-15px");
  }

}
