import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var $:any

@Component({
  selector: 'app-placements-sidebar',
  templateUrl: './placements-sidebar.component.html',
  styleUrls: ['./placements-sidebar.component.scss']
})
export class PlacementsSidebarComponent implements OnInit {

  page = null

  constructor(private router: Router) { }

  ngOnInit() {
    const url = this.router.url.replace(/\//g, '');
    switch(url) {
      case 'placements': this.page = 'placements';
      break;
      case 'our-recruiters': this.page = 'our-recruiters';
      break;
      default: this.page = '';
    }
  }

  

  // For mobile view
  public mobileFilterBack() {
    $('.collection-filter').css("left", "-365px");
  }

}
