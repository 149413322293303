import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-blog-details-one',
  templateUrl: './blog-details-one.component.html',
  styleUrls: ['./blog-details-one.component.scss']
})
export class BlogDetailsOneComponent implements OnInit {

  constructor(private http: HttpClient, private ActivatedRoute: ActivatedRoute, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  ngOnInit() {
/*     this.ActivatedRoute.params.subscribe(params => {
      let id = params['id'];
    
      console.log(`${id}`);
      this.http.get<any>(this.api_endpoint+'/blogsbyid/' + `${id}`).subscribe(
        data => {
          // console.log(data.data);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
    }); */
  }

}
