import { Component, OnInit, Input, ViewEncapsulation, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'about-courses',
  templateUrl: './about-courses.component.html',
  styleUrls: ['./about-courses.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AboutCoursesComponent implements OnInit {

   public about: null;
   public course: null;
   public engineeringcourse: [];
   public degreecourse: null;
   public managementcourse: null;
   public puccourse: null;
   public browseby: null;
   public placementsnews: null;
   public placementsnews1: null;
   public placementsnews2: null;
   public placementsnews3: null;
   public placementsnews4: null;
   public placementsnews5: null;
   public placementsnews6: null;
   public placementsnews7: null;
   public placementsnews8: null;
   public news: [];
   public news1: null;
   public news2: null;
   public news3: null;
   public news4: null;
   aboutSection: boolean = false ;
   courseSection: boolean = false ;
   placementsnewsSection: boolean = false ;
   newsSection: boolean = false ;

  constructor(private http: HttpClient, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  ngOnInit() {
    //aboutsection
    this.http.get<any>(this.api_endpoint+'/sectionsbypageid/'+'5e37b97437b4e931f0110854'+'/5e357a212e66f8213c278fc4').subscribe(
        data => {
          if(data.data != null){
            this.aboutSection = true;
            this.about = data.data;
          }else{
            this.aboutSection = false;
          }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
    });

    //coursesection
    this.http.get<any>(this.api_endpoint+'/sectionsbypageid/'+'5e37bbca37b4e931f0110858'+'/5e357a212e66f8213c278fc4').subscribe(
        data => {
          if(data.data != null){
            this.courseSection = true;
            this.course = data.data;
          }else{
            this.courseSection = false;
          }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
    });

    // placementsnewssection
    this.http.get<any>(this.api_endpoint+'/sectionsbypageid/'+'5e37bfd437b4e931f011085f'+'/5e357a212e66f8213c278fc4').subscribe(
      data => {
        if(data.data != null){
          this.placementsnewsSection = true;
          this.placementsnews = data.data;
        }else{
          this.placementsnewsSection = false;
        }
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });

    // newssection
    this.http.get<any>(this.api_endpoint+'/sectionsbypageid/'+'5e37c29e37b4e931f011086e'+'/5e357a212e66f8213c278fc4').subscribe(
      data => {
        if(data.data != null){
          this.newsSection = true;
          this.news = data.data;
        }else{
          this.newsSection = false;
        }
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("Client-side error occured.");
      } else {
        console.log("Server-side error occured.");
      }
    });

    this.http.get<any>(this.api_endpoint+'/browsebyabout').subscribe(
        data => {
          this.browseby = data.data;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
    });



    
      this.http.get<any>(this.api_endpoint+'/placementsnews').subscribe(
          data => {
            for(let i=0; i<=data.data.length; i++){
              if(data.data[i].priority == '1'){
              this.placementsnews1 = data.data[i];
              }else if(data.data[i].priority == '2'){
              this.placementsnews2 = data.data[i];
              }else if(data.data[i].priority == '3'){
              this.placementsnews3 = data.data[i];
              }else if(data.data[i].priority == '4'){
              this.placementsnews4 = data.data[i];
              }else if(data.data[i].priority == '5'){
              this.placementsnews5 = data.data[i];
              }else if(data.data[i].priority == '6'){
              this.placementsnews6 = data.data[i];
              }else if(data.data[i].priority == '7'){
              this.placementsnews7 = data.data[i];
              }else if(data.data[i].priority == '8'){
              this.placementsnews8 = data.data[i];
              }
            }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
      });
    
    
      this.http.get<any>(this.api_endpoint+'/news').subscribe(
          data => {
            for(let i=0; i<=data.data.length; i++){
              if(data.data[i].priority == '1'){
              this.news1 = data.data[i];
              }else if(data.data[i].priority == '2'){
              this.news2 = data.data[i];
              }else if(data.data[i].priority == '3'){
              this.news3 = data.data[i];
              }else if(data.data[i].priority == '4'){
              this.news4 = data.data[i];
              }
            }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
      });
    
    this.http.get<any>(this.api_endpoint+'/coursess/Engineering').subscribe(
          data => {
            this.engineeringcourse = data.data;
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
      });
    this.http.get<any>(this.api_endpoint+'/coursess/Degree').subscribe(
          data => {
            this.degreecourse = data.data;
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
      });
    this.http.get<any>(this.api_endpoint+'/coursess/Management').subscribe(
          data => {
            this.managementcourse = data.data;
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
      });
    this.http.get<any>(this.api_endpoint+'/coursess/PUC').subscribe(
          data => {
            this.puccourse = data.data;
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
          } else {
            console.log("Server-side error occured.");
          }
      });
  }
  public catSlideConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 586,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
 
}
