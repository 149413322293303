import { Component, OnInit, ViewEncapsulation, Inject  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-lifehkbk',
  templateUrl: './lifehkbk.component.html',
  styleUrls: ['./lifehkbk.component.scss']
})
export class LifehkbkComponent implements OnInit {

  constructor( private formBuilder: FormBuilder, private http: HttpClient, private ActivatedRoute: ActivatedRoute, private router: Router, @Inject('API_ENDPOINT') private api_endpoint: string) { }

  public type = null;
  public residential_facilities = [];
  public organizations_clubs = [];
  public health_wellness = [];
  public music_arts_culture = [];
  public community_services = [];
  public events = [];
  public entrepreneurships = [];

  ngOnInit() {

    $('.toggle-nav').on('click', function() {
      $('.sm-horizontal').css("right", "0px");
    });
    $(".mobile-back").on('click', function() {
      $('.sm-horizontal').css("right", "-410px");
    });
    
    this.ActivatedRoute.params.subscribe(params => {
      let lifehkbk = params['lifehkbk'];

      switch(`${lifehkbk}`){
        case 'overview': this.type = 'overview'
        break;
        default: this.type = ''
      }
      this.http.get<any>(this.api_endpoint+'/findbyfacility/residential-facilities').subscribe(
        data => {
          if(data.data != null){
            this.residential_facilities = data.data;
            console.log(this.residential_facilities)
          }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
      this.http.get<any>(this.api_endpoint+'/findbyfacility/organizations-clubs').subscribe(
        data => {
          if(data.data != null){
            this.organizations_clubs = data.data;
          }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
      this.http.get<any>(this.api_endpoint+'/findbyfacility/health-wellness').subscribe(
        data => {
          if(data.data != null){
            this.health_wellness = data.data;
          }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
      this.http.get<any>(this.api_endpoint+'/findbyfacility/music-arts-culture').subscribe(
        data => {
          if(data.data != null){
            this.music_arts_culture = data.data;
          }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
      this.http.get<any>(this.api_endpoint+'/findbyfacility/community-services').subscribe(
        data => {
          if(data.data != null){
            this.community_services = data.data;
          }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
      this.http.get<any>(this.api_endpoint+'/findbyfacility/events').subscribe(
        data => {
          if(data.data != null){
            this.events = data.data;
          }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
      this.http.get<any>(this.api_endpoint+'/findbyfacility/entrepreneurship').subscribe(
        data => {
          if(data.data != null){
            this.entrepreneurships = data.data;
          }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("Client-side error occured.");
        } else {
          console.log("Server-side error occured.");
        }
      });
           

 

    });
  }
  public productSlideConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
};

}
