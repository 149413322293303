// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  megaMenu?: boolean;
  megaMenuType?: string; // small, medium, large
  image?: string;
  children?: Menu[];
}

export const MENUITEMS: Menu[] = [
	{
		title: 'About', type: 'sub', megaMenu: true, megaMenuType: 'large', children: [
	      { path: 'about-us', title: 'About Us', type: 'extTabLink' },          
	      { path: 'home/one', title: 'Campus Leadership', type: 'extTabLink'},         
	      { path: 'vision-mission', title: 'Press Media', type: 'extTabLink' },      
	      { path: 'home/one', title: 'IQAC', type: 'extTabLink' },      
	      { path: 'home/one', title: 'NIRF', type: 'extTabLink' },      
	    ]
	},
	/* {
		title: 'About', type: 'sub', megaMenu: true, megaMenuType: 'large', children: [
			{ path: 'about-us', title: 'About HKBK Group', type: 'link' },          
			{ path: 'home/one', title: 'History', type: 'extTabLink'},         
			{ path: 'vision-mission', title: 'Mission & Vision', type: 'extTabLink' },         
			{	title: 'Leadership',  type: 'sub', children: [
					{ path: 'leadership/Chairman', title: 'Chairman',  type: 'extLink' },
					{ path: 'leadership/Director', title: 'Director',  type: 'extLink' },
					{ path: 'home/one', title: 'Secretary ',  type: 'link' }
					]
			},			
			{	title: 'Governance',  type: 'sub', children: [
					{ path: 'home/one', title: 'Governing Body',  type: 'link' },
					{ path: 'home/one', title: 'Academic Council',  type: 'link' },
					{ path: 'home/one', title: 'Board of Governors ',  type: 'link' }
					]
			},       
			{ path: 'home/one', title: 'Faculty/Staff Details', type: 'extTabLink' },        
			{	title: 'Messages',  type: 'sub', children: [
					{ path: 'home/one', title: 'Chairman',  type: 'link' },
					{ path: 'home/one', title: 'Principal',  type: 'link' }
					
					]
			},   
			{ path: 'home/one', title: 'Collaboration', type: 'extTabLink' },   
			{ path: 'home/one', title: 'Annual Reports (Finance)', type: 'extTabLink' }
	    ]
	}, */
	{
		title: 'Our Institutions',  type: 'sub', megaMenu: true, megaMenuType: 'large', children: [
			{ path: 'Engineering', title: 'HKBK College of Engineering', type: 'link' }, 
			{ path: 'Engineering', title: 'HKBK Degree College', type: 'link' }, 
			{ path: 'Engineering', title: 'HKBK Evening Degree College', type: 'link' }, 
			{ path: 'Engineering', title: 'HKBK College of Physiotherapy', type: 'link' }, 
			{ path: 'Engineering', title: 'HKBK Pre University(PUC)', type: 'link' }, 
		]
	},
	{
		title: 'Programs',  type: 'sub', megaMenu: true, megaMenuType: 'large', children: [ 
		  {	title: '4 YEAR ENGINEERING PROGRAMS',  type: 'sub', children: [
		      	{ path: 'home/one', title: 'Computer Science & Engineering',  type: 'link' },
		      	{ path: 'home/one', title: 'Electronics & Comm. Engineering',  type: 'link' },
		      	{ path: 'home/one', title: 'Mechanical Engineering ',  type: 'link' },
		      	{ path: 'home/one', title: 'Information Science Engineering ',  type: 'link' },
		      	{ path: 'home/one', title: 'Civil Engineering ',  type: 'link' },
		      	]
		  },			
	      {	title: '3 YEAR DEGREE PROGRAMS',  type: 'sub', children: [
		      	{ path: 'home/one', title: 'B.Com + Amazon Certifications',  type: 'link' },
		      	{ path: 'home/one', title: 'B.B.A + Google Certifications',  type: 'link' },
		      	{ path: 'home/one', title: 'B.C.A + Microsoft Certifications',  type: 'link' },
		      	{ path: 'home/one', title: 'B.B.A - Aviation',  type: 'link' }
		      	]
		  },       
	      {	title: 'MANAGEMENT',  type: 'sub', children: [
		      	{ path: 'home/one', title: 'MBA with Dual specialization',  type: 'link' },
		      	]
		  },     
	      {	title: 'ALLIED HEALTH SCIENCES',  type: 'sub', children: [
		      	{ path: 'home/one', title: 'Bachelor of Physiotherapy(BPT)',  type: 'link' },
		      	]
		  },     
	      {	title: 'PRE UNIVERSITY (PUC)',  type: 'sub', children: [
		      	{ path: 'home/one', title: 'PCMB',  type: 'link' },
		      	{ path: 'home/one', title: 'PCMC',  type: 'link' },
		      	{ path: 'home/one', title: 'EBAC',  type: 'link' },
		      	{ path: 'home/one', title: 'HEBA',  type: 'link' },
		      	]
		  },     
	     
	    ]
	},
	{
		title: 'ADMISSIONS',  type: 'sub', megaMenu: true, megaMenuType: 'large', children: [
	      { path: 'admissions/admission', title: 'Overview', type: 'extTabLink' },          
	      { path: 'home/one', title: 'Admission 2020', type: 'extTabLink'},         
	      { path: 'home/one', title: 'How to Apply', type: 'extTabLink' },         
	      { path: 'home/one', title: 'Scholarships', type: 'extTabLink' },         
	      { path: 'home/one', title: 'Indian Admissions', type: 'extTabLink' },         
	      { path: 'home/one', title: 'International Admissions', type: 'extTabLink' },         
	    ]
	},
	
	/* {
		title: 'Academics',  type: 'sub', megaMenu: true, megaMenuType: 'large', children: [
	      { path: 'academics/institute-industry-interaction', title: 'Institute-Industry Interaction', type: 'extTabLink' },          
	      { path: 'academics/curriculum-enhancement', title: 'Curriculum Enhancement', type: 'extTabLink'},         
	      { path: 'academics/guest-lectures-workshops', title: 'Guest Lectures Workshops', type: 'extTabLink' },         
	      { path: 'academics/workshops', title: 'Workshops', type: 'extTabLink' },         
	      { path: 'academics/mentoring-and-advising', title: 'Mentoring & Advising', type: 'extTabLink' },         
	      { path: 'academics/innovations-of-students', title: 'Innovations of Students', type: 'extTabLink' },         
	      { path: 'academics/research-hkbk', title: 'Research Hkbk', type: 'extTabLink' },         
	      { path: 'academics/conferences', title: 'Conferences', type: 'extTabLink' },         
	      { path: 'academics/human-resource-development', title: 'Human Resource Development', type: 'extTabLink' },  
		]
	}, */
	{
		title: 'Placements', type: 'sub', megaMenu: true, megaMenuType: 'large', children: [
	      { path: 'placements/overview', title: 'Overview', type: 'extTabLink' },          
	      { path: 'placements/our-recruiters', title: 'Our Recruiters', type: 'extTabLink' },          
	      { path: 'home/one', title: 'Training & Development', type: 'extTabLink'},         
	      { path: 'home/one', title: 'Pool Campus', type: 'extTabLink' },                
	      { path: 'home/one', title: 'Campus Drives', type: 'extTabLink' },                
	    ]
	},
	{
		title: 'LIFE@HKBK', type: 'sub', megaMenu: true, megaMenuType: 'large', children: [
	      { path: 'home/one', title: 'Overview', type: 'extTabLink' },          
	      { path: 'home/one', title: 'Hostels', type: 'extTabLink'},         
	      { path: 'home/one', title: 'Cultural & Cosmopolitan', type: 'extTabLink' },  
	      { path: 'home/one', title: 'Student Affairs', type: 'extTabLink' },   
	      { path: 'home/one', title: 'Clubs & Activities', type: 'extTabLink' },    
	      { path: 'home/one', title: 'Sports & Adventure', type: 'extTabLink' },   
	      { path: 'home/one', title: 'Academic Calendar (Calendar of Events COE)', type: 'extTabLink' },  
	    ]
	},
	{
		title: 'RESEARCH & INNOVATION', type: 'sub', megaMenu: true, megaMenuType: 'large', children: [
	      { path: 'home/one', title: 'Centers of Excellence', type: 'extTabLink' },          
	      { path: 'home/one', title: 'Industry Partnerships', type: 'extTabLink'},         
	      { path: 'home/one', title: 'Patents', type: 'extTabLink' },  
	      { path: 'home/one', title: 'Business Incubation center', type: 'extTabLink' },   
	      { path: 'home/one', title: 'Innovation & Entrepreneurship Cell', type: 'extTabLink' },     
	    ]
	},
	

]